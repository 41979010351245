import * as S from './styles'
import {Selector} from "react-redux";

interface LoadingOverlayProps {
    selector: Selector<any, any>
}

export const LoadingOverlay = ({selector}: LoadingOverlayProps) => {
  const loading = selector((state: any) => state.loading)

  return loading ? (
    <S.Wrapper>
      <div className="overlay__inner">
        <div className="overlay__content">
          <span className="spinner"></span>
        </div>
      </div>
    </S.Wrapper>
  ) : (
    <></>
  )
}
