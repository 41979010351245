import api from "./api";
import { CreateSimplifiedSmartContract } from "../pages/CreateSimplifiedSmartContract";
import { VerifySimplifiedSmartContract } from "../pages/VerifySimplifiedSmartContract";
import { HistoryFilterForm } from "../components/organisms/HistoryFilter";

interface Response {
  id: string;
  bank_billet_url?: string;
}

export const sendSimplifiedContractDeathCertificate = async (
  token: string,
  data: CreateSimplifiedSmartContract
) => {

  return api.post<Response>("", data, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const sendSimplifiedContract = async (
  token: string,
  data: CreateSimplifiedSmartContract
) => {
  const formData = new FormData();

  formData.append("object", data.object);
  formData.append("value", data.value);
  formData.append("document_type", data.document_type);
  formData.append("document", data.document);
  formData.append("name", data.name);
  formData.append("payment_type", data.payment_type);
  formData.append("scope", data.scope);

  if (data.additional_info) {
    formData.append("additional_info", data.additional_info);
  }

  if (data.additional_info_2) {
    formData.append("additional_info_2", data.additional_info_2);
  }

  if (data.additional_info_3) {
    formData.append("additional_info_3", data.additional_info_3);
  }

  formData.append("credit_card_info", JSON.stringify(data.credit_card_info));
  //eslint-disable-next-line
  formData.append("file", data.file!);

  return api.post<Response>("", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  });
};

interface VerifyResponse {
  match: boolean;
}

export const verifySimplifiedContract = async (
  data: VerifySimplifiedSmartContract,
  token?: string
) => {
  const formData = new FormData();

  formData.append("id", data.id);
  //eslint-disable-next-line
  formData.append("file", data.file!);

  return api.post<VerifyResponse>("/verify", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  });
};

export async function getMyContractHistory(
  token: string,
  page?: number,
  filter_data?: HistoryFilterForm
) {
  const response = await api.get("", {
    headers: {
      authorization: `Bearer ${token}`,
    },
    params: {
      page,
      ...filter_data,
    },
  });
  return response.data;
}

export async function getLedger(
  page?: number,
  filter_data?: HistoryFilterForm
) {
  const response = await api.get("/get-ledger", {
    params: {
      page,
      ...filter_data,
    },
  });
  return response.data;
}

export async function getContractById(id: string) {
  const response = await api.get(`/${id}`);
  return response.data;
}
