import { useToasts } from 'react-toast-notifications'
import {IAppContext} from "@growthtech/token-mfes-shared/src/interfaces/app-context";
import {useContext} from "react";

export const useIsLogged = (history: any, context: React.Context<IAppContext>) => {
  const { user } = useContext(context)
  const { addToast } = useToasts()

  if (!user) {
    history.push('/login')
    addToast('Você precisa estar logado para acessar esta página.', {
      appearance: 'error',
      autoDismiss: true
    })
  }
}
