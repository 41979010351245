import { Form } from 'react-bootstrap'
import * as React from 'react'
import ErrorText from '../ErrorText'
import { InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next';


export interface SelectFieldOption {
  label: string
  value: string
}

type SelectFieldTypes = InputHTMLAttributes<HTMLSelectElement>

export type SelectFieldProps = {
  label?: string
  value?: string | number
  errorMessage?: string
  emptyOptionText?: string
  options: SelectFieldOption[]
  i18nErrorMessage?: boolean
  notDisabledEmpty?: boolean
} & Omit<SelectFieldTypes, 'size' | 'value'>

export const SelectField = ({
  label,
  errorMessage,
  options,
  emptyOptionText,
  i18nErrorMessage=false,
  notDisabledEmpty=false,
  ...other
}: SelectFieldProps) => {
  const { t } = useTranslation();

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <Form.Control isInvalid={!!errorMessage} as="select" {...other}>
        {emptyOptionText && (
          <option value="" disabled={!notDisabledEmpty}>
            {emptyOptionText}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>

      {errorMessage && !i18nErrorMessage && <ErrorText text={errorMessage} />}
      {errorMessage && i18nErrorMessage && <ErrorText text={t(errorMessage)} />}
    </Form.Group>
  )
}
