import * as React from "react";
import {useForm, Controller} from "react-hook-form";
import { SelectField } from "@growthtech/token-mfes-shared/src/atoms/SelectField";
import { TextInputWithMask } from "@growthtech/token-mfes-shared/src/atoms/TextInputWithMask";
import getObjectTypes from "../../../services/getObjectTypes";
import {Button, Col, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';



export interface HistoryFilterForm {
    type: string
    status: string
    period_start: string
    period_end: string
}

interface HistoryFilterProps {
    onContinue: (data: HistoryFilterForm) => void,
    disableStatus?: boolean
}

export const HistoryFilter = ({onContinue, disableStatus}: HistoryFilterProps) => {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<HistoryFilterForm>({
        defaultValues: {
            type: "",
            status: "",
            period_start: "",
            period_end: ""
        },
    });

    return <form onSubmit={handleSubmit(onContinue)}><Row>
        <Col md={2}>
            <Controller
                name="period_start"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextInputWithMask
                        mask="99/99/9999"
                        label={t('filtros.periodo_inicio')}
                        errorMessage={errors?.period_start?.message}
                        value={value}
                        placeholder="DD/MM/AAAA"
                        onChange={onChange}
                    />
                )}
            />
        </Col>
        <Col md={2}>
            <Controller
                name="period_end"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <TextInputWithMask
                        mask="99/99/9999"
                        label={t('filtros.periodo_fim')}
                        errorMessage={errors?.period_end?.message}
                        value={value}
                        placeholder="DD/MM/AAAA"
                        onChange={onChange}
                    />
                )}
            />
        </Col>
        <Col md={3}>
            <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectField
                        label={t('filtros.tipo')}
                        onChange={onChange}
                        value={value}
                        options={[
                            {
                                label: t('filtros.todos'),
                                value: "",
                            },
                            ...getObjectTypes(t)
                        ]}
                    />
                )}
            />
        </Col>
        {! disableStatus && <Col md={3}>
            <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <SelectField
                        label="Status"
                        onChange={onChange}
                        value={value}
                        options={[
                            {
                                label: t('filtros.todos'),
                                value: "",
                            },
                            {
                                label: t('filtros.aguardando_pagamento'),
                                value: "waiting_payment",
                            },
                            {
                                label: t('filtros.registrado'),
                                value: "paid",
                            }
                        ]}
                    />
                )}
            />
        </Col>}
        <Col md={2} className="align-self-end mb-3">
            <Button type="submit" variant="primary">
                {t('filtros.botao_filtrar')}
            </Button>
        </Col>
    </Row>
    </form>
}
