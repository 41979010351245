import * as React from 'react'
import { Alert } from 'react-bootstrap'

interface InformationProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode
  type?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
}

export default function Information({
  children,
  type = 'warning',
  ...others
}: InformationProps) {
  return (
    <Alert variant={type} style={{ marginTop: '10px' }} {...others}>
      {children ?? 'Sem Registros.'}
    </Alert>
  )
}
