import * as React from 'react'
import { useTranslation } from 'react-i18next';

export const BankBilletPending = ({
  bankBilletUrl
}: {
  bankBilletUrl: string
}) => {
    const { t } = useTranslation();

    return (
    <>
      <h4 style={{margin: '0 0 15px'}}>{t('registrar.resultado.boleto.aguardando_pagamento')}</h4>
      <p style={{fontSize: '16px'}}>
          {t('registrar.resultado.boleto.frase_1')}
        <br />
        <br />
          {t('registrar.resultado.boleto.frase_2')}
      </p>
      <a className="btn btn-primary" href={bankBilletUrl} target="_blank" rel="noreferrer">
          {t('registrar.resultado.boleto.botao_boleto')}
      </a>
    </>
  )
}
