import logo from '../../../assets/images/logo-growth-tech.svg'

export const GTLogo = () => {
  return (
    <div className="d-flex justify-content-end mt-3">
      <img
        style={{ width: '250px', marginRight: '20px' }}
        alt="logo growth tech"
        src={logo}
      />
    </div>
  )
}
