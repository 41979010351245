import { Card, Col, Row } from "react-bootstrap";
import * as React from "react";
import { useContext, useState } from "react";
import {
  sendSimplifiedContract,
  sendSimplifiedContractDeathCertificate
} from "../../services/simplified-smart-contracts";
import { useToasts } from "react-toast-notifications";
import { useIsLogged } from "@growthtech/token-mfes-auth/src/hooks/useIsLogged";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { Party } from "../../components/organisms/SimplifiedContractParties";
import { HeaderCard } from "../HeaderCard";
import { SuccessCard } from "./parts/success-card";
import { LoadingCard } from "./parts/loading-card";
import { Form } from "./parts/form";
import { CreditCardForm } from "./parts/credit-card-form";
import { GuestFactoryProps } from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";
import { GTLogo } from "@growthtech/token-mfes-shared/src/atoms/GTLogo";
import { useTranslation } from "react-i18next";

export interface CreditCardInfo {
  name: string;
  document: string;
  birth: string;
  installments: number;
  payment_token: string;
  billing_address: {
    street: string;
    number: string;
    neighborhood: string;
    zipcode: string;
    city: string;
    state: string;
  };
}

export interface CreateSimplifiedSmartContract {
  email?: string;
  object: string;
  value: string;
  document: string;
  document_type: string;
  name: string;
  file_type: string;
  file_size: number;
  terms_accepted: boolean;
  file?: File;
  parties?: Party[];
  payment_type: string;
  credit_card_info?: CreditCardInfo;
  scope: string;
  additional_info?: string;
  additional_info_2?: string;
  additional_info_3?: string;

  // Field death certificate
  nome: string
  data_registro?: string
  nro_registro?: string
  tipo_obito?: string
  inscricao?: string
  plano?: string
  sexo?: string
  cor?: string
  profissao?: string
  natural?: string
  residencia?: string
  cep?: string
  bairro?: string
  cidade?: string
  uf?: string
  inss?: string
  id_beneficio?: string
  est_civil?: string
  rg?: string
  dt_expedicao?: string
  orgao_emissor?: string
  cpf?: string
  // bens?: boolean
  bens?: string
  // testamento?: boolean
  testamento?: string
  // eleitor?: boolean
  eleitor?: string
  numero?: string
  zona?: string
  secao?: string
  cidade_eleitor?: string
  reserv?: string
  ident?: string
  nascimento?: string
  idade?: string
  religiao?: string
  nacionalidade?: string
  carteira_trabalho?: string

  // Conjuge
  conjuge_nome?: string
  data_casamento?: string
  cartorio_casamento?: string
  casamento_cartorio_livro?: string
  casamento_cartorio_folha?: string
  casamento_cartorio_numero?: string

  // Filiacao
  pai_nome?: string
  pai_est_civil?: string
  pai_natural?: string
  pai_profissao?: string
  pai_endereco?: string
  pai_bairro?: string
  pai_cidade?: string
  pai_uf?: string
  mae_nome?: string
  mae_est_civil?: string
  mae_natural?: string
  mae_profissao?: string
  mae_endereco?: string
  mae_bairro?: string
  mae_cidade?: string
  mae_uf?: string

  // Dados do Óbito
  data_falecimento?: string
  falecimento_local?: string
  falecimento_cidade?: string
  falecimento_uf?: string
  sepultamento?: string
  sepultamento_local?: string
  sepultamento_cidade?: string
  sepultamento_uf?: string
  medico_1_nome?: string
  medico_1_crm?: string
  medico_1_decl?: string
  medico_2_nome?: string
  medico_2_crm?: string
  causa_1?: string
  causa_2?: string
  causa_3?: string
  causa_4?: string

  // Filhos
  filho_1_nome?: string
  filho_1_idade?: number
  filho_2_nome?: string
  filho_2_idade?: number
  filho_3_nome?: string
  filho_3_idade?: number
  filho_4_nome?: string
  filho_4_idade?: number
  observacao?: string

  // Declarante
  declarante_cartorio?: string
  declarante_cartorio_end?: string
  declarante_cartorio_fone?: string
  declarante_nome?: string
  declarante_rg?: string
  declarante_org_exp?: string
  declarante_cpf?: string
  declarante_profissao?: string
  declarante_est_civil?: string
  declarante_nacionalidade?: string
  declarante_parentesco?: string
  declarante_endereco?: string
  declarante_bairro?: string
  declarante_cidade?: string
  declarante_cep?: string
  declarante_estado?: string
  declarante_fone?: string
  declarante_fone2?: string
  declarante_fone3?: string

  // Atendente
  atendente_nome?: string
  atendente_rg?: string
  atendente_org_exp?: string
  atendente_lancamento?: string
}

interface CreateSimplifiedSmartContractProps {
  history: IHistoryPush;
}

type Props = CreateSimplifiedSmartContractProps & GuestFactoryProps;

export const CreateSimplifiedSmartContract = ({ history, context }: Props) => {
  document.title = "Registra Fácil";
  const { addToast } = useToasts();
  const { t } = useTranslation();

  type Steps = "form" | "loading" | "credit_card_form" | "success";

  const [step, setStep] = useState<Steps>("form");

  const [file, setFile] = useState<File>();
  const [paymentType, setPaymentType] = useState("bank_billet");
  const [bankBilletUrl, setBankBilletUrl] = useState("");

  const [firstStepData, setFirstStepData] =
    useState<CreateSimplifiedSmartContract>();

  useIsLogged(history, context);

  const { user, menuVisible, setMenuVisible } = useContext(context);

  const send = async (data: CreateSimplifiedSmartContract) => {
    try {
      setMenuVisible(false);
      setStep("loading");
      //eslint-disable-next-line

      if (data.object == "Óbito") {
        setPaymentType("free")
        await sendSimplifiedContractDeathCertificate(user!.token, data);
      } else {
        const res = await sendSimplifiedContract(user!.token, {
          ...data,
          payment_type: paymentType,
          file,
        });

        if (paymentType === "bank_billet") {
          setBankBilletUrl(res.data.bank_billet_url!);
        }
      }

      setMenuVisible(true);
      setStep("success");
    } catch (e) {
      console.log({
        e
      })
      setMenuVisible(true);
      setStep("form");
      addToast(t("geral.erro_desconhecido"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const onContinue = async (data: CreateSimplifiedSmartContract) => {
    setFirstStepData(data);
    setPaymentType(data.payment_type);

    if (data.payment_type === "credit_card") {
      setMenuVisible(false);
      setStep("credit_card_form");
    } else {
      await send(data);
    }
  };

  const onFinishCreditCard = async (credit_card_info: CreditCardInfo) => {
    if (!firstStepData) {
      return addToast(t("geral.erro_desconhecido"), {
        appearance: "error",
        autoDismiss: true,
      });
    }

    await send({
      ...firstStepData,
      credit_card_info,
    });
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col md={12}>
            <HeaderCard context={context} showMenu={menuVisible} />
            <h4 className="text-white f-w-700 mb-4">{t("registrar.titulo")}</h4>

            {step === "credit_card_form" && (
              <CreditCardForm
                onFinish={onFinishCreditCard}
                setMenuVisible={setMenuVisible}
              />
            )}
            {step === "success" && (
              <SuccessCard
                payment_type={paymentType}
                bankBilletUrl={bankBilletUrl}
              />
            )}
            {step === "loading" && <LoadingCard />}
            {step === "form" && (
              <Form onContinue={onContinue} setFile={setFile} file={file} />
            )}
          </Col>
        </Row>
        <GTLogo />
      </div>
    </div>
  );
};
