import {useEffect, useState} from "react";
import {HistoryFilterForm} from "../components/organisms/HistoryFilter";
import {SimplifiedContract} from "../pages/Ledger";
import {getLedger, getMyContractHistory} from "../services/simplified-smart-contracts";

interface useRemoteContractsParams {
    my?: boolean
    token?: string
}

export const useRemoteContracts = ({my, token}: useRemoteContractsParams) => {

    const remoteFn = my ? getMyContractHistory : getLedger

    const [contracts, setContracts] = useState<SimplifiedContract[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [maxPage, setMaxPage] = useState<number>(0)
    const [filterData, setFilterData] = useState<HistoryFilterForm>({
        status: '',
        type: '',
        period_end: '',
        period_start: ''
    })

    const remote = (page?: number, filter_data?: HistoryFilterForm) => {
        return my && token ? getMyContractHistory(token, page, filter_data) : getLedger(page, filter_data)
    }

    const reloadData = (page?: number, filter_data?: HistoryFilterForm) => {
        remote(page, filter_data).then((results) => {
            setContracts(results.data)
            setMaxPage(results.maxPage)
            setCurrentPage(results.currentPage)
        });
    }

    useEffect(() => {
        reloadData(1, filterData)
    }, [filterData])

    useEffect(() => {
        reloadData()
    }, []);

    return {
        setFilterData,
        maxPage,
        contracts,
        currentPage,
        reloadData
    }

}
