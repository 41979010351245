import React, { useContext, useState } from "react";

import { NavLink } from "react-router-dom";
import { Alert, Card, Row, Col } from "react-bootstrap";
import { ContentSeparator } from "@growthtech/token-mfes-shared/src/atoms/ContentSeparator";

import { GTLogo } from "@growthtech/token-mfes-shared/src/atoms/GTLogo";
import { PasswordField } from "@growthtech/token-mfes-shared/src/atoms/PasswordField";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./signin.schema";
import { ISignInForm } from "./signin-form.interface";
import { TextInputWithMask } from "@growthtech/token-mfes-shared/src/atoms/TextInputWithMask";
import { signIn } from "@growthtech/token-mfes-auth/src/services/auth";
import { useToasts } from "react-toast-notifications";
import { LoadingIndicator } from "@growthtech/token-mfes-shared/src/atoms/LoadingIndicator";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { useIsGuest } from "@growthtech/token-mfes-auth/src/hooks/useIsGuest";
import { GuestFactoryProps } from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";
import { HeaderCard } from "../HeaderCard";
import { useTranslation } from "react-i18next";

interface SignInOriginalProps {
  history: IHistoryPush;
}

type SignInProps = SignInOriginalProps & GuestFactoryProps;

const SignIn = ({ history, context }: SignInProps) => {
  const project_name = "Registra Fácil";
  document.title = `${project_name} - Login`;
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  useIsGuest(history, context);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInForm>({
    resolver: yupResolver(schema),
  });

  const { setUser } = useContext(context);

  const [accessDenied, setAccessDenied] = useState<boolean>(false);

  const redirect_after_login_url =
    process.env.REACT_APP_REDIRECT_AFTER_LOGIN_URL || "/dashboard";

  const onSend: SubmitHandler<ISignInForm> = async (data) => {
    try {
      setLoading(true);
      const response = await signIn(data);
      setUser?.({
        token: response.access_token,
        name: response.name,
      });
      // TODO: Remove this setTimeout later (added to avoid redirecting to dashboard before the user has set to the context)
      setTimeout(() => {
        setAccessDenied(false);
        history.push(redirect_after_login_url);
        setLoading(false);
        addToast("Login efetuado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
      }, 500);
    } catch (e) {
      setLoading(false);
      if (e.response.status === 401) {
        setAccessDenied(true);
      } else if (e.response.status === 403) {
        addToast(e.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        setAccessDenied(false);
        addToast("Ocorreu um erro desconhecido.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <HeaderCard context={context} />
          <Row>
            <Col md={6}>
              <div className="appear-from-left">
                <h3
                  className="text-white f-w-700"
                  style={{ fontSize: "min(5vw, 1.75rem)" }}
                >
                  {t("login.titulo1")}
                </h3>
                <h3
                  className="text-white f-w-700"
                  style={{ fontSize: "min(5vw, 1.75rem)" }}
                >
                  {t("login.titulo2")}
                </h3>
                <h3
                  className="text-white f-w-700"
                  style={{ fontSize: "min(5vw, 1.75rem)" }}
                >
                  {t("login.titulo3")}
                </h3>
                <h3
                  className="text-white f-w-700 mb-4"
                  style={{ fontSize: "min(5vw, 1.75rem)" }}
                >
                  {t("login.titulo4")}
                </h3>
              </div>
            </Col>

            <Col md={6} sm={12}>
              <div className="appear-from-right">
                <Card>
                  <Card.Body className="card-small-padding">
                    <h5 className="mb-4">{t("login.subtitulo")}</h5>
                    <Controller
                      control={control}
                      name="cpf"
                      render={({ field }) => (
                        <TextInputWithMask
                          disabled={loading}
                          mask="999.999.999-99"
                          label={t("login.usuario")}
                          placeholder="000.000.000-00"
                          errorMessage={errors.cpf?.message}
                          i18nErrorMessage={true}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="password"
                      render={({ field: { onChange, value } }) => (
                        <PasswordField
                          label={t("login.senha")}
                          disabled={loading}
                          onChange={onChange}
                          value={value}
                          errorMessage={errors.password?.message}
                          i18nErrorMessage={true}
                        />
                      )}
                    />

                    {accessDenied && (
                      <Alert variant="danger">{t("login.erro")}</Alert>
                    )}
                    <div className="d-flex flex-column justify-content-end mt-2">
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-primary pull-left"
                          type="submit"
                          disabled={loading}
                        >
                          {!loading ? t("login.entrar") : <LoadingIndicator />}
                        </button>
                      </div>
                      <div className="d-flex justify-content-end mt-4">
                        {!loading && (
                          <p className="mb-2 text-muted pull-left">
                            <ContentSeparator
                              left={
                                <NavLink
                                  to="/abra-sua-conta"
                                  className="f-w-400"
                                >
                                  {t("login.criar_conta")}
                                </NavLink>
                              }
                              right={
                                <NavLink
                                  to="/esqueci-minha-senha"
                                  className="f-w-400"
                                >
                                  {t("login.esqueci_minha_senha")}
                                </NavLink>
                              }
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </form>
        <div className="appear-from-right">
          <GTLogo />
        </div>{" "}
      </div>
    </div>
  );
};
export default SignIn;
