import {Pagination} from 'react-bootstrap'

interface PaginationItem {
    number: number
    active: number,
    onClick?: any
}

const PaginationItem = ({number, active, onClick}: PaginationItem) => {
    return <Pagination.Item key={number} active={number === active} onClick={onClick}>
        {number}
    </Pagination.Item>
}

interface PaginationProps {
    currentPage: number,
    onChangePage: (page: number) => void
    maxPage: number
}

export const CustomPagination = ({maxPage, currentPage, onChangePage}: PaginationProps) => {
    return <Pagination>
        <Pagination.First disabled={currentPage == 1} onClick={() => onChangePage(1)} />
        <Pagination.Prev  onClick={() => onChangePage(currentPage - 1)} disabled={currentPage -1 < 1} />


        {currentPage - 3 >= 1 && <PaginationItem number={currentPage - 3} active={currentPage} onClick={() => onChangePage(currentPage - 3)} />}
        {currentPage - 2 >= 1 && <PaginationItem number={currentPage - 2} active={currentPage} onClick={() => onChangePage(currentPage - 2)} />}
        {currentPage - 1 >= 1 && <PaginationItem number={currentPage - 1} active={currentPage} onClick={() => onChangePage(currentPage - 1)} />}

        <PaginationItem number={currentPage} active={currentPage} />

        {currentPage + 1 <= maxPage && <PaginationItem number={currentPage + 1} active={currentPage}  onClick={() => onChangePage(currentPage + 1)} />}
        {currentPage + 2 <= maxPage && <PaginationItem number={currentPage + 2} active={currentPage} onClick={() => onChangePage(currentPage + 2)} />}
        {currentPage + 3 <= maxPage && <PaginationItem number={currentPage + 3} active={currentPage} onClick={() => onChangePage(currentPage + 3)} />}

        <Pagination.Next onClick={() => onChangePage(currentPage + 1)} disabled={currentPage + 1 > maxPage} />
        <Pagination.Last onClick={() => onChangePage(maxPage)} disabled={currentPage === maxPage} />
    </Pagination>
}
