import { Button, Card, Col, Row, Table } from "react-bootstrap";
import * as React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { HeaderCard } from "../HeaderCard";
import { getContractById } from "../../services/simplified-smart-contracts";
import {
  getStatusBadge,
  SimplifiedContract,
} from "../HistorySimplifiedSmartContract";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { GuestFactoryProps } from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";
import { useTranslation } from "react-i18next";

interface DetailSimplifiedSmartContractProps {
  history: IHistoryPush;
  match: any;
}

type Props = DetailSimplifiedSmartContractProps & GuestFactoryProps;

export const DetailSimplifiedSmartContract = ({
  history,
  match,
  context,
}: Props) => {
  const [contract, setContract] = useState<SimplifiedContract>();
  document.title = "Registra Fácil";
  const { t } = useTranslation();

  const id = match.params.id;

  useEffect(() => {
    getContractById(id).then((data) => setContract(data));
  }, []);

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col md={12}>
            <>
              <HeaderCard context={context} />
              <h4 className="text-white f-w-700 mb-4">
                {t("detalhes.titulo")}
              </h4>
              <Card>
                <Card.Body className="card-small-padding">
                  <>
                    {contract && (
                      <>
                        <p style={{ fontSize: "17px" }}>
                          {t("detalhes.subtitulo")}{" "}
                          {/* <span style={{ fontWeight: "700", color: "#5d6ea7" }}>
                            {contract.id}
                          </span> */}
                        </p>

                        <Table striped responsive className="details-table">
                          <tbody>
                            <tr>
                              <th>ID</th>
                              <td>{contract.id}</td>
                            </tr>
                            <tr>
                              <th>{t("detalhes.data_hora")}</th>
                              <td>
                                {moment(contract.created_at).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>{t("detalhes.tipo")}</th>
                              <td>{t("document_types." + contract.object)}</td>
                            </tr>
                            {
                              contract.object != "Óbito" &&                             <>
                                <tr>
                                  <th>{t("detalhes.valor")}</th>
                                  <td>{contract.value}</td>
                                </tr>
                                {/*<tr>*/}
                                {/*  <th>{t("detalhes.publico_ou_privado")}</th>*/}
                                {/*  <td>{contract.scope}</td>*/}
                                {/*</tr>*/}
                                <tr>
                                  <th>{t("detalhes.campo_livre")}</th>
                                  <td>{contract.additional_info}</td>
                                </tr>
                                <tr>
                                  <th>{t("detalhes.campo_livre")} 2</th>
                                  <td>{contract.additional_info_2}</td>
                                </tr>
                                <tr>
                                  <th>{t("detalhes.campo_livre")} 3</th>
                                  <td>{contract.additional_info_3}</td>
                                </tr>
                              </>
                            }
                            {/*<tr>*/}
                            {/*  <th>{t("detalhes.responsavel")}</th>*/}
                            {/*  <td>*/}
                            {/*    {contract.name} - {contract.document}*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            <tr>
                              <th>{t("detalhes.hash_documento")}</th>
                              <td style={{ wordBreak: "break-all" }}>
                                {contract.documentHash}
                              </td>
                            </tr>
                            <tr>
                              <th>{t("detalhes.hash_transacao")}</th>
                              <td style={{ wordBreak: "break-all" }}>
                                {contract.transaction_hash}
                              </td>
                            </tr>
                            <tr>
                              <th>{t("detalhes.pagamento")}</th>
                              <td>
                                {getStatusBadge(contract.status, t)}{" "}
                                {contract.paid_at &&
                                  moment(contract.paid_at).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <th>{t("detalhes.documento")}</th>
                              <td>
                                <a
                                  className="btn btn-primary"
                                  href={contract.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("detalhes.botao_visualizar")}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>Transação</th>
                              <td>
                                {contract.transaction_hash && (
                                  <a
                                    className="btn btn-primary"
                                    href={`${process.env.REACT_APP_BLOCKCHAIN_EXPLORER}/tx/${contract.transaction_hash}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {t("detalhes.botao_validar")}
                                  </a>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                    <Row>
                      <Col>
                        {" "}
                        <Button
                          onClick={() => history.goBack()}
                          size="sm"
                          variant="primary"
                        >
                          {t("geral.voltar")}
                        </Button>
                      </Col>
                    </Row>
                  </>
                </Card.Body>
              </Card>
            </>
          </Col>
        </Row>
      </div>
    </div>
  );
};
