export default (t: any) => [
    // {
    //     label: t("document_types.Obito"),
    //     value: "Óbito",
    // },
    {
        label: t("document_types.Contrato de Compra e Venda"),
        value: "Contrato de Compra e Venda",
    },
    {
        label: t("document_types.Contrato de Locação"),
        value: "Contrato de Locação",
    },
    {
        label: t("document_types.Contrato de Arrendamento"),
        value: "Contrato de Arrendamento",
    },
    {
        label: t("document_types.Contrato de Leasing"),
        value: "Contrato de Leasing",
    },
    {
        label: t("document_types.Contrato de Comodato"),
        value: "Contrato de Comodato",
    },
    {
        label: t("document_types.Contrato de Prestação de Serviços"),
        value: "Contrato de Prestação de Serviços",
    },
    {
        label: t("document_types.Contrato Mútuo"),
        value: "Contrato Mútuo",
    },
    {
        label: t("document_types.Contrato de Financiamento"),
        value: "Contrato de Financiamento",
    },
    {
        label: t("document_types.Contrato de Permuta"),
        value: "Contrato de Permuta",
    },
    {
        label: t("document_types.Contrato de Cessão de Direitos"),
        value: "Contrato de Cessão de Direitos",
    },
    {
        label: t("document_types.Contrato de Crédito"),
        value: "Contrato de Crédito",
    },
    {
        label: t("document_types.Contrato de Alienação Fiduciária"),
        value: "Contrato de Alienação Fiduciária",
    },
    {
        label: t("document_types.Apólice de seguro"),
        value: "Apólice de seguro",
    },
    {
        label: t("document_types.Garantia"),
        value: "Garantia",
    },
    {
        label: t("document_types.Declaração"),
        value: "Declaração",
    },
    {
        label: t("document_types.Carta"),
        value: "Carta",
    },
    {
        label: t("document_types.Ofício"),
        value: "Ofício",
    },
    {
        label: t("document_types.Memorando"),
        value: "Memorando",
    },
    {
        label: t("document_types.Projeto"),
        value: "Projeto",
    },
    {
        label: t("document_types.Desenho"),
        value: "Desenho",
    },
    {
        label: t("document_types.Ata"),
        value: "Ata",
    },
    {
        label: t("document_types.Doação"),
        value: "Doação",
    },
    {
        label: t("document_types.Testamento"),
        value: "Testamento",
    },
    {
        label: t("document_types.Inventário"),
        value: "Inventário",
    },
    {
        label: t("document_types.Escritura"),
        value: "Escritura",
    },
    {
        label: t("document_types.Pacto Antenupcial"),
        value: "Pacto Antenupcial",
    },
    {
        label: t("document_types.União estável"),
        value: "União estável",
    },
    {
        label: t("document_types.Certidão"),
        value: "Certidão",
    },
    {
        label: t("document_types.Conta de luz"),
        value: "Conta de luz",
    },
    {
        label: t("document_types.Conta de água"),
        value: "Conta de água",
    },
    {
        label: t("document_types.Conta de telefone"),
        value: "Conta de telefone",
    },
    {
        label: t("document_types.Conta de telefone celular"),
        value: "Conta de telefone celular",
    },
    {
        label: t("document_types.Conta de outros serviços"),
        value: "Conta de outros serviços",
    },
    {
        label: t("document_types.Extrato bancário"),
        value: "Extrato bancário",
    },
    {
        label: t("document_types.Hipoteca"),
        value: "Hipoteca",
    },
    {
        label: t("document_types.Edital"),
        value: "Edital",
    },
    {
        label: t("document_types.Termo"),
        value: "Termo",
    },
    {
        label: t("document_types.Outro"),
        value: "Outro",
    },
]
