import axios from 'axios'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BLOCKCHAIN_SERVICE_URL}/simplified-ethereum`,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default api
