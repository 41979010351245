import styled, { css } from 'styled-components'

type ButtonType = React.HTMLProps<HTMLButtonElement> & {active: boolean}

export const Button = styled.button<ButtonType>`
    border: none;
    background-color: transparent;
    opacity: ${(props => (props.active ? 1 : 0.3))}
`

export const ImgWrapper = styled.img`
   width: 30px;
   
`
