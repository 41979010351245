import { Button, Card, Col, Row } from "react-bootstrap";
import { CreditCardInfo } from "../index";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "@growthtech/token-mfes-shared/src/atoms/TextInput";
import * as React from "react";
import { SelectField } from "@growthtech/token-mfes-shared/src/atoms/SelectField";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./credit-card.schema";
import { TextInputWithMask } from "@growthtech/token-mfes-shared/src/atoms/TextInputWithMask";
import { LoadingIndicator } from "@growthtech/token-mfes-shared/src/atoms/LoadingIndicator";
import getCreditCardBrandFromNumber from "@growthtech/token-mfes-billings/src/utils/getCreditCardBrandFromNumber";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { useGetCep } from "@growthtech/token-mfes-shared/src/hooks/useGetCep";
import { CepDto } from "@growthtech/token-mfes-shared/src/domain/usecases/Cep";

interface CreditCardFormProps {
  onFinish: (data: CreditCardInfo) => Promise<void>;
  setMenuVisible: (visible: boolean) => void;
}

interface CreditCardData {
  name: string;
  document: string;
  birth: string;
  brand: string;
  card_number: string;
  cvv: string;
  expiration_month: string;
  expiration_year: string;
  zipcode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
}

let gerenciaNetReady = false;

export const CreditCardForm = ({
  onFinish,
  setMenuVisible,
}: CreditCardFormProps) => {
  const { t } = useTranslation();

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { getCep } = useGetCep();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreditCardData>({
    resolver: yupResolver(schema),
    defaultValues: {
      expiration_year: "",
      expiration_month: "",
      state: "",
    },
  });

  const checkCep = React.useCallback(async (cep: string) => {
    const cepDto = await getCep(cep);
    if (!cepDto) return;
    updateAddressState(cepDto);
  }, []);

  const updateAddressState = (cepDto: CepDto) => {
    setValue("zipcode", cepDto.cep);
    setValue("street", cepDto.logradouro);
    setValue("neighborhood", cepDto.bairro);
    setValue("city", cepDto.localidade);
    setValue("state", cepDto.uf);
  };

  const getPaymentTokenCallback = async (error: any, response: any) => {
    if (error) {
      setLoading(false);
      // Trata o erro ocorrido
      console.error(error);

      const { error: errorMessage } = error;

      if (errorMessage == "invalid_card_number") {
        setError("card_number", {
          type: "manual",
          message: t("pagamento.erro_cartao_invalido"),
        });
      }
    } else {
      const {
        data: { payment_token },
      } = response;

      const data = getValues();

      setMenuVisible(true);
      setLoading(false);

      await onFinish({
        name: data.name,
        document: data.document,
        installments: 1,
        payment_token,
        birth: data.birth,
        billing_address: {
          zipcode: data.zipcode.replace(/\D/g, ""),
          city: data.city,
          street: data.street,
          neighborhood: data.neighborhood,
          state: data.state,
          number: data.number,
        },
      });
    }
  };

  const onContinue = (data: CreditCardData) => {
    setMenuVisible(false);
    setLoading(true);

    try {
      const brand = getCreditCardBrandFromNumber(data.card_number);

      // @ts-ignore
      $gn.checkout.getPaymentToken(
        {
          brand, // bandeira do cartão
          number: data.card_number, // número do cartão
          cvv: data.cvv, // código de segurança
          expiration_month: data.expiration_month, // mês de vencimento
          expiration_year: data.expiration_year, // ano de vencimento
        },
        getPaymentTokenCallback
      );
    } catch (e) {
      addToast(t("geral.erro_desconhecido"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    if (!gerenciaNetReady) {
      const script = document.createElement("script");
      script.async = true;
      script.innerHTML =
        "var s=document.createElement('script');s.type='text/javascript';var v=parseInt(Math.random()*1000000);s.src='https://sandbox.gerencianet.com.br/v1/cdn/70597e49ae4786b62882fef31399a7d5/'+v;s.async=false;s.id='70597e49ae4786b62882fef31399a7d5';if(!document.getElementById('70597e49ae4786b62882fef31399a7d5')){document.getElementsByTagName('head')[0].appendChild(s);};$gn={validForm:true,processed:false,done:{},ready:function(fn){$gn.done=fn;}};";
      document.body.appendChild(script);

      // @ts-ignore
      $gn.ready(function (checkout) {
        setMenuVisible(true);
        setReady(true);
        gerenciaNetReady = true;
      });
      return () => {
        document.body.removeChild(script);
      };
    } else {
      setMenuVisible(true);
      setReady(true);
    }
  }, []);

  return (
    <>
      {ready ? (
        <>
          <form onSubmit={handleSubmit(onContinue)}>
            <Card className="mt-3">
              <Card.Body className="card-small-padding">
                <Row>
                  <Col md={12}>
                    <h5 className="mb-4">{t("pagamento.subtitulo")}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          label={t("pagamento.campo_nome")}
                          placeholder={t("pagamento.campo_nome_placeholder")}
                          errorMessage={errors?.name?.message}
                          value={value}
                          onChange={onChange}
                          disabled={loading}
                          i18nErrorMessage={true}
                        />
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <Controller
                      name="document"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInputWithMask
                          label={t("pagamento.campo_cpf")}
                          errorMessage={errors?.document?.message}
                          mask="999.999.999-99"
                          placeholder="999.999.999-99"
                          value={value}
                          onChange={onChange}
                          disabled={loading}
                          i18nErrorMessage={true}
                        />
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <Controller
                      name="birth"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInputWithMask
                          mask="99/99/9999"
                          label={t("pagamento.campo_nascimento")}
                          errorMessage={errors?.birth?.message}
                          value={value}
                          placeholder="DD/MM/AAAA"
                          onChange={onChange}
                          disabled={loading}
                          i18nErrorMessage={true}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Controller
                      name="card_number"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          label={t("pagamento.campo_nro_cartao")}
                          errorMessage={errors?.card_number?.message}
                          value={value}
                          onChange={onChange}
                          disabled={loading}
                          i18nErrorMessage={true}
                        />
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Controller
                          name="expiration_month"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <SelectField
                              label={t("pagamento.campo_mes_expiracao")}
                              errorMessage={errors?.expiration_month?.message}
                              i18nErrorMessage={true}
                              onChange={onChange}
                              value={value}
                              emptyOptionText={t("geral.select_placeholder")}
                              disabled={loading}
                              options={[
                                { label: "1", value: "01" },
                                { label: "2", value: "02" },
                                { label: "3", value: "03" },
                                { label: "4", value: "04" },
                                { label: "5", value: "05" },
                                { label: "6", value: "06" },
                                { label: "7", value: "07" },
                                { label: "8", value: "08" },
                                { label: "9", value: "09" },
                                { label: "10", value: "10" },
                                { label: "11", value: "11" },
                                { label: "12", value: "12" },
                              ]}
                            />
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <Controller
                          name="expiration_year"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <SelectField
                              label={t("pagamento.campo_ano_expiracao")}
                              onChange={onChange}
                              value={value}
                              emptyOptionText={t("geral.select_placeholder")}
                              disabled={loading}
                              errorMessage={errors?.expiration_year?.message}
                              i18nErrorMessage={true}
                              options={[
                                { label: "2022", value: "2022" },
                                { label: "2023", value: "2023" },
                                { label: "2024", value: "2024" },
                                { label: "2025", value: "2025" },
                                { label: "2026", value: "2026" },
                                { label: "2027", value: "2027" },
                                { label: "2028", value: "2028" },
                                { label: "2029", value: "2029" },
                                { label: "2030", value: "2030" },
                                { label: "2031", value: "2031" },
                                { label: "2032", value: "2032" },
                                { label: "2033", value: "2033" },
                                { label: "2034", value: "2034" },
                                { label: "2035", value: "2035" },
                              ]}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Controller
                      name="cvv"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          label={t("pagamento.campo_cvv")}
                          errorMessage={errors?.cvv?.message}
                          i18nErrorMessage={true}
                          value={value}
                          placeholder="CVV"
                          disabled={loading}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body className="card-small-padding">
                <Row>
                  <Col md={12}>
                    <h5 className="mb-4">
                      {t("pagamento.subtitulo_endereco")}
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Controller
                      name="zipcode"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInputWithMask
                          mask="99999-999"
                          disabled={loading}
                          label={t("endereco.cep")}
                          placeholder="99999-999"
                          errorMessage={errors?.zipcode?.message}
                          i18nErrorMessage={true}
                          value={value}
                          onChange={(event) => {
                            onChange(event.target.value);
                            return checkCep(event.target.value);
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="street"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          disabled={loading}
                          label={t("endereco.endereco")}
                          placeholder={t("endereco.endereco_placeholder")}
                          errorMessage={errors?.street?.message}
                          i18nErrorMessage={true}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <Controller
                      name="number"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          disabled={loading}
                          label={t("endereco.numero")}
                          placeholder="9999"
                          errorMessage={errors?.number?.message}
                          i18nErrorMessage={true}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectField
                          label={t("endereco.estado")}
                          disabled={loading}
                          errorMessage={errors?.state?.message}
                          i18nErrorMessage={true}
                          onChange={onChange}
                          value={value}
                          emptyOptionText={t("geral.select_placeholder")}
                          options={[
                            { label: "AC", value: "AC" },
                            { label: "AL", value: "AL" },
                            { label: "AP", value: "AP" },
                            { label: "AM", value: "AM" },
                            { label: "BA", value: "BA" },
                            { label: "CE", value: "CE" },
                            { label: "DF", value: "DF" },
                            { label: "ES", value: "ES" },
                            { label: "GO", value: "GO" },
                            { label: "MA", value: "MA" },
                            { label: "MT", value: "MT" },
                            { label: "MS", value: "MS" },
                            { label: "MG", value: "MG" },
                            { label: "PA", value: "PA" },
                            { label: "PB", value: "PB" },
                            { label: "PR", value: "PR" },
                            { label: "PE", value: "PE" },
                            { label: "PI", value: "PI" },
                            { label: "RJ", value: "RJ" },
                            { label: "RN", value: "RN" },
                            { label: "RS", value: "RS" },
                            { label: "RO", value: "RO" },
                            { label: "RR", value: "RR" },
                            { label: "SC", value: "SC" },
                            { label: "SP", value: "SP" },
                            { label: "SE", value: "SE" },
                            { label: "TO", value: "TO" },
                          ]}
                        />
                      )}
                    />
                  </Col>
                  <Col md={5}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          label={t("endereco.cidade")}
                          placeholder={t("endereco.cidade_placeholder")}
                          disabled={loading}
                          errorMessage={errors?.city?.message}
                          i18nErrorMessage={true}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                  <Col md={5}>
                    <Controller
                      name="neighborhood"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          disabled={loading}
                          label={t("endereco.bairro")}
                          placeholder={t("endereco.bairro_placeholder")}
                          errorMessage={errors?.neighborhood?.message}
                          i18nErrorMessage={true}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <div className="d-flex justify-content-end">
                      <Button variant="primary" type="submit" className="btn">
                        {!loading ? (
                          t("registrar.botao_continuar")
                        ) : (
                          <LoadingIndicator />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </form>
        </>
      ) : (
        <p>
          <LoadingIndicator />
        </p>
      )}
    </>
  );
};
