import * as yup from 'yup'

export default yup.object().shape({
  file_size: yup
    .number()
    .required('validacao.consultar.file_size_required')
    .max(
      10000000,
      'validacao.consultar.file_size_max'
    ),
  file_type: yup
    .string()
    .required('validacao.consultar.file_size_required')
    .test(
      'fileType',
        'validacao.consultar.file_type',
      (value) => value === 'application/pdf'
    ),

  id: yup.string().required('validacao.consultar.id_required')
})
