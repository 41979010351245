import * as React from "react";
import { NavLink } from "react-router-dom";
import { Card } from "react-bootstrap";

import { logo } from "@growthtech/token-mfes-shared/src/contants/logo";
import { DevelopedByGT } from "@growthtech/token-mfes-shared/src/atoms/DevelopedByGT";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./forgotPassword.schema";
import { TextInputWithMask } from "@growthtech/token-mfes-shared/src/atoms/TextInputWithMask";
import { forgotPassword } from "@growthtech/token-mfes-auth/src/services/auth";
import { useToasts } from "react-toast-notifications";
import { IForgotPasswordForm } from "./forgotPassword-form.interface";
import * as S from "../SignUp/styles";
import { useState } from "react";
import { LoadingIndicator } from "@growthtech/token-mfes-shared/src/atoms/LoadingIndicator";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({ history }: { history: IHistoryPush }) => {
  document.title = "Registra Fácil";
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(schema),
  });

  const onSend: SubmitHandler<IForgotPasswordForm> = async (data) => {
    try {
      setLoading(true);
      await forgotPassword(data);
      history.push("/login");
      setLoading(false);
      addToast(t("esqueci_minha_senha.mensagem_email"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (e) {
      setLoading(false);
      if (e.response.status === 400) {
        addToast(t("esqueci_minha_senha.cpf_nao_encontrado"), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t("geral.erro_desconhecido"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <div className="d-flex justify-content-around align-items-center row">
                    <img src={logo} alt="" className="logo mb-4" />
                  </div>{" "}
                  <Controller
                    control={control}
                    name="cpf"
                    render={({ field: { onChange, value } }) => (
                      <TextInputWithMask
                        disabled={loading}
                        mask="999.999.999-99"
                        label={t("esqueci_minha_senha.subtitulo")}
                        placeholder={t("esqueci_minha_senha.cpf_placeholder")}
                        errorMessage={errors?.cpf?.message}
                        i18nErrorMessage={true}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <S.ButtonsWrapper>
                    <div className="">
                      {!loading && (
                        <NavLink to="/login" className="f-w-400">
                          <a className="btn">{t("geral.voltar")}</a>
                        </NavLink>
                      )}
                    </div>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? (
                          t("esqueci_minha_senha.botao")
                        ) : (
                          <LoadingIndicator />
                        )}
                      </button>
                    </div>
                  </S.ButtonsWrapper>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  );
};
export default ForgotPassword;
