import * as yup from 'yup'
import { isValid } from 'cpf'

export default yup.object().shape({
  name: yup.string().required('validacao.sign_up.name_required'),
  phone: yup.string().required('validacao.sign_up.phone_required'),
  email: yup.string().required('validacao.sign_up.email_required'),
  cpf: yup
    .string()
    .test('is-cpf', 'validacao.sign_up.cpf_is_valid', (value) => isValid(value as string))
    .required('validacao.sign_up.cpf_required'),
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'validacao.sign_up.password_matches'
    )
    .required('validacao.sign_up.password_required'),
  password_confirmation: yup
    .string()
    .test(
      'is-equal-password',
        'validacao.sign_up.password_confirmation_matches',
      (value, context) => context.parent.password === value
    )
    .required('validacao.sign_up.password_confirmation_required'),

  privacy_policy: yup
    .boolean()
    .oneOf(
      [true],
        'validacao.sign_up.privacy_policy_accepted'
    )
})
