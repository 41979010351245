import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Alert, Card } from "react-bootstrap";

import { logo } from "@growthtech/token-mfes-shared/src/contants/logo";

import { DevelopedByGT } from '@growthtech/token-mfes-shared/src/atoms/DevelopedByGT'
import { useEffect, useState } from 'react'
import { confirmEmail } from '@growthtech/token-mfes-auth/src/services/auth'
import { useTranslation } from 'react-i18next';

interface MatchParams extends RouteComponentProps {
  params: { token: string };
}

const ConfirmEmail = ({ match }: { match: MatchParams }) => {
  document.title = "Registra Fácil";
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const token = match.params.token;

  useEffect(() => {
    confirmEmail(token)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setError(false);
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        setError(true);
      });
  }, [token]);

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Card>
          <div className="row align-items-center">
            <div className="col-md-12">
              <Card.Body>
                <img src={logo} alt="" className="logo" />
                {loading && (
                  <h5 className="f-w-700">
                    {t('confirmar_email.mensagem_aguarde')}
                  </h5>
                )}
                {success && (
                  <>
                    <Alert variant="success">
                      {t('confirmar_email.frase_1')}
                      <br />
                      {t('confirmar_email.frase_2')}
                    </Alert>
                    <div className="d-flex flex-row justify-content-around mt-4">
                      <Link to="/login">
                        <a>{t('confirmar_email.botao')}</a>
                      </Link>
                    </div>
                  </>
                )}
                {error && (
                  <Alert variant="danger">
                    {t('confirmar_email.mensagem_erro')}
                  </Alert>
                )}
              </Card.Body>
            </div>
          </div>
        </Card>
        <DevelopedByGT />
      </div>
    </div>
  );
};
export default ConfirmEmail;
