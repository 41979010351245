import * as React from 'react'
import { useTranslation } from 'react-i18next';

export const FreeDocumentRegistered = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 style={{margin: '0 0 15px'}}>{t('registrar.resultado.gratis.sucesso')}</h4>
      <p style={{fontSize: '16px'}}>
        {t('registrar.resultado.gratis.frase_1')}
      </p>
    </>
  )
}
