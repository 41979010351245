import * as React from 'react'

import * as S from './styles'

interface ErrorTextProps {
  text: string
}

export default function ErrorText({ text, ...other }: ErrorTextProps) {
  return <S.Error {...other}>{text}</S.Error>
}
