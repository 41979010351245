import { BankBilletPending } from "./bank-billet-pending";
import { CreditCardPending } from "./credit-card-pending";
import {Card, Col, Row} from "react-bootstrap";
import * as React from "react";
import {FreeDocumentRegistered} from "./free-document-registered";

export const SuccessCard = ({
  payment_type,
  bankBilletUrl,
}: {
  payment_type: string;
  bankBilletUrl: string;
}) => {
  return <>
      <Card>
          <Card.Body className="card-small-padding">
              <div className="d-flex">
                  <div>
                    {payment_type != "free" ?
                      <i className="fa fa-file-invoice-dollar" style={{color: '#5d6ea7', fontSize: '120px'}}/>
                      : <i className="fa fa-check" style={{color: 'green', fontSize: '120px'}}/>
                    }
                  </div>
                <div className="ml-4">
                {payment_type === "bank_billet" && (
                          <BankBilletPending bankBilletUrl={bankBilletUrl} />
                      )}
                      {payment_type === "credit_card" && <CreditCardPending />}
                      {payment_type == "free" && <FreeDocumentRegistered />}
                  </div>
              </div>
          </Card.Body>
      </Card>
  </>
};
