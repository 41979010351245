import { Centered } from "@growthtech/token-mfes-shared/src/templates/Centered";
import loadingBlockchainImg from "../../../../assets/loading-blockchain.gif";
import { Card } from "react-bootstrap";
import * as React from "react";
import { useTranslation } from 'react-i18next';

export const LoadingCard = () => {
    const { t } = useTranslation();

    return (
      <Card>
          <Card.Body>
              <Centered>
                  <h5>{t('registrar.carregando')}</h5>
                  <img
                      style={{ width: "400px", maxWidth: "90%" }}
                      src={loadingBlockchainImg}
                  />
              </Centered>
          </Card.Body>
      </Card>

  );
};
