import * as yup from 'yup'

export default yup.object().shape({
  password: yup
    .string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'validacao.reset_password.password_matches'
    )
    .required('validacao.reset_password.password_required'),
  password_confirmation: yup
    .string()
    .test(
      'is-equal-password',
        'validacao.reset_password.password_confirmation_matches',
      (value, context) => context.parent.password === value
    )
    .required('validacao.reset_password.password_confirmation_required')
})
