import * as React from 'react'
import { Suspense, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  useLocation,
  RouteComponentProps,
  Redirect
} from 'react-router-dom'
import 'font-awesome/scss/font-awesome.scss'
import Loader from './components/theme-originals/layout/Loader'
import ScrollToTop from './components/theme-originals/layout/ScrollToTop'
import guestRoutes, { RouteObject } from './routes/guest.routes'
import './assets/scss/style.scss'
import { RoutesFactory } from './routes/logged.routes'
import { LoadingOverlay } from '@growthtech/token-mfes-shared/src/atoms/LoadingOverlay'
import { LoggedRoutesContainer } from './components/atoms/LoggedRoutesContainer'
import { useLoadingSelector } from './store/reducers/reducer'
import { AxiosHttpClient } from '@growthtech/token-mfes-http/src/infra/http/axiosHttpClient'
import { RemoteGetUser } from '@growthtech/token-mfes-users/src/remotes/RemoteGetUser'
import {
  IAppContext,
  User
} from '@growthtech/token-mfes-shared/src/interfaces/app-context'

export const AppContext = React.createContext<IAppContext>({
  setMenuVisible: () => console.log('set menu visible')
})
const App = () => {
  const location = useLocation()

  const storageUser = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : undefined
  const [user, setUserState] = useState<User | null>(storageUser)
  const [menuVisible, setMenuVisible] = useState<boolean>(true)
  const [routes, setRoutes] = useState<RouteObject[]>([])

  const setUser = (user: User) => {
    const firstAndLastName = (nameArray: string[]) =>
      `${nameArray[0]} ${nameArray[nameArray.length - 1]}`
    localStorage.setItem('user', JSON.stringify(user))
    const axiosHttpClient = new AxiosHttpClient(user.token)
    const remoteGetUser = new RemoteGetUser(axiosHttpClient)
    remoteGetUser.getMe().then((data) => {
      setUserState({
        ...user,
        name: firstAndLastName(data.name.split(' ')),
        fullName: data.name,
        type: data.type
      })
      setRoutes(RoutesFactory.createRoutes(data as User))
    })
  }
  useEffect(() => {
    if (storageUser) {
      setUser(storageUser)
    }
  }, [])

  const clearUser = () => {
    localStorage.removeItem('user')
    setUserState(null)
  }

  const value = React.useMemo(
    () => ({ user, setUser, clearUser, setMenuVisible, menuVisible }),
    [user]
  )
  return (
    <>
      <AppContext.Provider value={value}>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Route path={guestRoutes.map((x) => x.path)}>
              <Switch location={location} key={location.pathname}>
                {guestRoutes.map((route, index) => {
                  return route.component ? (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      render={(props: RouteComponentProps) => (
                        <route.component {...props} context={AppContext} />
                      )}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                })}
                {!user && <Redirect to={'/'} />}
              </Switch>
            </Route>
            <Route path={routes.map((x) => x.path)}>
              <LoggedRoutesContainer />
            </Route>
          </Suspense>
        </ScrollToTop>
      </AppContext.Provider>
      <div className="backdrop" />
      <LoadingOverlay selector={useLoadingSelector} />
    </>
  )
}

export default App
