import { useContext } from 'react'
import { IHistoryPush } from '@growthtech/token-mfes-shared/src/interfaces/router'
import {IAppContext} from "@growthtech/token-mfes-shared/src/interfaces/app-context";

export const useIsGuest = (history: IHistoryPush, context: React.Context<IAppContext>) => {
  const { user } = useContext(context)

  const url = process.env.REACT_APP_REDIRECT_AFTER_LOGIN_URL || '/dashboard';

  if (user) {
    history.push(url)
  }
}
