import { Form } from 'react-bootstrap'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next';
import ErrorText from '../ErrorText'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

type CheckboxProps = {
  label: string | React.ReactElement
  errorMessage?: string
    i18nErrorMessage?: boolean
} & TextFieldTypes

export const Checkbox = ({
  label,
  onChange,
  defaultChecked,
  errorMessage,
  i18nErrorMessage=false,
}: CheckboxProps) => {
    const { t } = useTranslation();

    return (
    <Form.Group>
      <div className="switch d-inline m-r-10">
        <Form.Control
          data-testid="checkbox"
          type="checkbox"
          id="checked-default"
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <Form.Label htmlFor="checked-default" className="cr" />
      </div>
      <Form.Label>{label}</Form.Label>
      {errorMessage && !i18nErrorMessage && (
        <div>
          <ErrorText text={errorMessage} />
        </div>
      )}
        {errorMessage && i18nErrorMessage && (
            <div>
                <ErrorText text={t(errorMessage)} />
            </div>
        )}
    </Form.Group>
  )
}
