import {UserModel} from "../domains/models/UserModel";
import {HttpClient} from "@growthtech/token-mfes-http/src/data/protocols/http/httpClient";
import {validateHttpStatus} from "@growthtech/token-mfes-http/src/validation/validateHttpResponse";

export class RemoteGetUser {
  constructor(
    private readonly httpClient: HttpClient
  ) {}

  @validateHttpStatus
  async getMe(): Promise<UserModel> {
    return await this.httpClient.get?.({
      url: `${process.env.REACT_APP_USERS_SERVICE_URL}/me`
    })
  }
}
