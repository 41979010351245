import { RouteObject } from './guest.routes'

import { UserType } from '@growthtech/token-mfes-users/src/domains/models/UserModel'
import { User } from '@growthtech/token-mfes-shared/src/interfaces/app-context'

// Tokens

export class RoutesFactory {
  static createRoutes(user: User) {
    switch (user.type) {
      case UserType.COMMON:
        return loggedRoutes
      case UserType.INVESTOR:
        return loggedAsInvestorRoutes
      default:
        return []
    }
  }
}

const loggedRoutes: RouteObject[] = []

export const loggedAsInvestorRoutes: RouteObject[] = []
export default loggedRoutes
