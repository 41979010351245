import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    padding: 0 10px 10px 0;
    i {
      margin-right: 5px;
      font-size: 20px;
    }
    span {
      font-size: 13px;
`
