import proptokenLogo from "../../assets/images/logo.svg";
import rfLogo from "../../assets/images/logo-rf.svg";

// TODO: Change logo for real project
import metrumLogo from "../../assets/images/logo.svg";

const projectName = process.env.REACT_APP_PROJECT_NAME;

const logos = {
  proptoken: proptokenLogo,
  metrum: metrumLogo,
  registrafacil: rfLogo,
};

//@ts-ignore
export const logo = logos[projectName.toLocaleLowerCase()];
