import api from './api'

interface SignInData {
  cpf: string
  password: string
}

export async function signIn(data: SignInData) {
  const response = await api.post('/login', data)
  return response.data
}

interface ForgotPasswordData {
  cpf: string
}

export async function forgotPassword(data: ForgotPasswordData) {
  const response = await api.post('/recover-password', data)
  return response.data
}

interface ResetPasswordData {
  hash: string
  password: string
  password_confirmation: string
}

export async function resetPassword(data: ResetPasswordData) {
  const response = await api.post('/reset-password', data)
  return response.data
}

export async function confirmEmail(token: string) {
  const response = await api.post('/confirm-email', {
    token
  })
  return response.data
}
