import apiUsers from "./api-users";

interface SignUpData {
  name: string
  email: string
  phone: string
  document: string
  password: string
  password_confirmation: string
  type: string
}

export async function signUp(data: SignUpData) {
  const response = await apiUsers.post('/', data)
  return response.data
}
