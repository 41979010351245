import { Button, Card, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./verify-simplified-smart-contract.schema";
import * as React from "react";
import { TextInput } from "@growthtech/token-mfes-shared/src/atoms/TextInput";
import { Centered } from "@growthtech/token-mfes-shared/src/templates/Centered";
import { useContext, useState } from "react";
import loadingBlockchainImg from "../../../assets/loading-blockchain.gif";
import { InputFile } from "@growthtech/token-mfes-shared/src/molecules/InputFile";
import { verifySimplifiedContract } from "../../services/simplified-smart-contracts";
import { useToasts } from "react-toast-notifications";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { HeaderCard } from "../HeaderCard";
import { GuestFactoryProps } from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";
import { GTLogo } from "@growthtech/token-mfes-shared/src/atoms/GTLogo";
import { useTranslation } from "react-i18next";

export interface VerifySimplifiedSmartContract {
  id: string;
  file_type: string;
  file_size: number;
  file?: File;
}

interface VerifySimplifiedSmartContractProps {
  history: IHistoryPush;
}

type Props = VerifySimplifiedSmartContractProps & GuestFactoryProps;

export const VerifySimplifiedSmartContract = ({ history, context }: Props) => {
  const { t } = useTranslation();

  document.title = "Registra Fácil";

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successData, setSuccessData] = useState<boolean>();
  const { addToast } = useToasts();
  const [file, setFile] = useState<File>();

  const { user } = useContext(context);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<VerifySimplifiedSmartContract>({
    resolver: yupResolver(schema),
  });

  const send = async (data: VerifySimplifiedSmartContract) => {
    try {
      setLoading(true);
      //eslint-disable-next-line
      const res = await verifySimplifiedContract(
        {
          ...data,
          file,
        },
        user?.token
      );
      setSuccessData(res.data.match);
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setLoading(false);
      addToast(t("geral.erro_desconhecido"), {
        appearance: "error",
        autoDismiss: true,
      });
      throw new Error(err);
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(send)}>
          <Row>
            <Col md={12}>
              <>
                <HeaderCard context={context} />

                <h4 className="text-white f-w-700 mb-4">
                  {t("consulta.titulo")}
                </h4>

                <Card>
                  <>
                    <Card.Body className="card-small-padding">
                      {!loading && (
                        <Row>
                          <Col md={12}>
                            <h5 className="mb-4">{t("consulta.subtitulo")}</h5>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={6}>
                          <InputFile
                            disabled={loading}
                            label={t("consulta.campo_pdf")}
                            btnText={
                              file?.name || t("consulta.placeholder_pdf")
                            }
                            onChange={(file) => {
                              setValue("file_type", file.type, {
                                shouldValidate: true,
                              });
                              setValue("file_size", file.size, {
                                shouldValidate: true,
                              });
                              setFile(file);
                            }}
                            errorMessage={
                              errors.file_type?.message ||
                              errors.file_size?.message
                            }
                            i18nErrorMessage={true}
                          />
                        </Col>
                        <Col md={6}>
                          <Controller
                            control={control}
                            name="id"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                label={t("consulta.campo_id")}
                                placeholder={t("consulta.placeholder_campo_id")}
                                errorMessage={errors.id?.message}
                                i18nErrorMessage={true}
                                value={value}
                                onChange={onChange}
                                disabled={loading}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {!loading && (
                        <Row>
                          <Col md={12}>
                            <div className="d-flex justify-content-end">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn"
                              >
                                {t("consulta.botao_validar")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Card.Body>
                  </>

                  {loading && (
                    <Centered>
                      <h5>{t("consulta.mensagem_aguarde")}</h5>
                      <img
                        style={{ width: "400px", maxWidth: "90%" }}
                        src={loadingBlockchainImg}
                      />
                    </Centered>
                  )}
                  {success && (
                    <>
                      {successData && (
                        <>
                          <br />
                          <div className="d-flex align-content-center">
                            <div>
                              <i
                                style={{ fontSize: "80px", color: "#95c467" }}
                                className="fa fa-check-square"
                              />
                            </div>
                            <div className="ml-4">
                              <h4
                                className="m-0 mb-3"
                                style={{ maxWidth: "100%" }}
                              >
                                {t("consulta.titulo_resultado")}
                              </h4>
                              <strong>
                                {t("consulta.subtitulo_resultado_sucesso")}
                              </strong>
                              <p>{t("consulta.resultado_sucesso")}</p>
                            </div>
                          </div>
                        </>
                      )}
                      {!successData && (
                        <>
                          <br />
                          <div className="d-flex align-content-center">
                            <div>
                              <i
                                style={{ fontSize: "80px", color: "#5d6ea7" }}
                                className="fa fa-times-circle"
                              />
                            </div>
                            <div className="ml-4">
                              <h4
                                className="m-0 mb-3"
                                style={{ maxWidth: "100%" }}
                              >
                                {t("consulta.titulo_resultado")}
                              </h4>
                              <strong>
                                {t("consulta.subtitulo_resultado_falha")}
                              </strong>
                              <p>{t("consulta.resultado_falha")}</p>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Card>
              </>
            </Col>
          </Row>
          <GTLogo />
        </form>
      </div>
    </div>
  );
};
