import { Button, Card, Col, Row } from "react-bootstrap";
import * as React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import { useContext } from "react";
import { GuestFactoryProps } from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";
import { LanguageSwitcher } from "@growthtech/token-mfes-shared/src/organisms/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export const Menu = ({ context }: GuestFactoryProps) => {
  const { clearUser, user } = useContext(context);
  const { t } = useTranslation();

  const logout = () => {
    clearUser?.();
    history.push("/login");
  };
  const history = useHistory();

  const active = location.pathname;

  return (
    <Card className="mb-4">
      <div className="card-small-padding">
        <Row className="d-flex justify-content-around align-items-center">
          <img src={logo} alt="" className="logo" />
          <div className="d-flex menu-items" style={{justifyContent: 'space-evenly'}}>
            <Button
              onClick={() => history.push("/verify-smart-contract")}
              size="sm"
              variant={
                active == "/verify-smart-contract" ? "link active" : "link"
              }
            >
              <i className="fa fa-check-circle" />
              {t("menu.validar")}
            </Button>
            {/*<Button*/}
            {/*  onClick={() => history.push("/ledger")}*/}
            {/*  size="sm"*/}
            {/*  variant={active == "/ledger" ? "link active" : "link"}*/}
            {/*>*/}
            {/*  <i className="fa fa-folder-open" />*/}
            {/*  {t("menu.registros")}*/}
            {/*</Button>*/}
            <Button
              disabled={!user}
              onClick={() => history.push("/smart-contract")}
              size="sm"
              variant={active == "/smart-contract" ? "link active" : "link"}
            >
              <i className="fa fa-file-contract" />
              {t("menu.registrar")}
            </Button>

            <Button
              disabled={!user}
              onClick={() => history.push("/history-smart-contract")}
              size="sm"
              variant={
                active == "/history-smart-contract" ? "link active" : "link"
              }
            >
              <i className="fa fa-file-text" />
              {t("menu.meus-registros")}
            </Button>
          </div>
          <div>
            <LanguageSwitcher />
          </div>
          {user ? (
            <div className="d-flex">
              <Button onClick={logout} variant="primary">
                {t("menu.sair")}
              </Button>
            </div>
          ) : (
            <div className="d-flex">
              <Button onClick={() => history.push("/login")} variant="primary">
                LOGIN
              </Button>
            </div>
          )}
        </Row>
      </div>
    </Card>
  );
};
