import * as React from 'react'
import { useTranslation } from 'react-i18next';

export const CreditCardPending = () => {
    const { t } = useTranslation();

    return (
    <>
      <h4 style={{margin: '0 0 15px'}}>{t('registrar.resultado.cartao.pagamento_analise')}</h4>
      <p style={{fontSize: '16px'}}>
          {t('registrar.resultado.cartao.frase_1')}
        <br />
        <br />
          {t('registrar.resultado.cartao.frase_2')}
      </p>
    </>
  )
}
