import { useTranslation } from 'react-i18next'

const canUseTranslation =
  process.env.REACT_APP_PROJECT_NAME === 'registraFacil'
export const LoadingIndicator = () => {
  const { t } = useTranslation();

  return (
    <>
      <span className="spinner-border spinner-border-sm mr-1" role="status" />
      {canUseTranslation ? `${t('geral.carregando')}...` : 'Carregando...'}
    </>
  )
}
