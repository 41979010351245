import { Menu } from "../Menu";
import * as React from "react";
import {GuestFactoryProps} from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";

type HeaderCardProps = {
  showMenu?: boolean;
} & GuestFactoryProps;

export const HeaderCard = ({ showMenu = true, context }: HeaderCardProps) => {
  return <>
    { showMenu && <Menu context={context} />}
  </>
};
