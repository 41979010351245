import * as yup from 'yup'
import { isValid } from 'cpf'

export default yup.object().shape({
  cpf: yup
    .string()
    // .test('is-cpf', 'Digite um CPF válido', (value) =>
    //   isValid(value as string)
    // )
    .required('validacao.sign_in.cpf_required'),
  password: yup.string().required('validacao.sign_in.password_required')
})
