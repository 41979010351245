import { useTranslation, Trans } from 'react-i18next';

import en from '../../../assets/images/languages/en.png'
import ptBR from '../../../assets/images/languages/pt-BR.png'
import * as S from './styles'

export const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation();

    const languages = [
        {name: 'Português', code: 'pt-BR'},
        {name: 'English', code: 'en'},
    ]

    return <>{languages.map((lng) => (
        <S.Button
            type="button"
            key={`btn-lng-${lng.code}`}
            style={{ fontWeight: i18n.resolvedLanguage === lng.code ? 'bold' : 'normal' }}
            onClick={() => i18n.changeLanguage(lng.code)}
            active={ i18n.resolvedLanguage === lng.code }
            >
             <S.ImgWrapper src={lng.code === "pt-BR" ? ptBR : en}/>
        </S.Button>
    ))}
    </>
}
