import api from '../../services/api'

export interface CpfDto {
  nome: string
}

export default class CpfService {
  static async get(cpf: string): Promise<CpfDto> {
    const res = await api.get<CpfDto>(`/cpf/${cpf}`)
    return res.data
  }
}
