import * as React from "react";

import { Card } from "react-bootstrap";

import { logo } from "@growthtech/token-mfes-shared/src/contants/logo";

import { DevelopedByGT } from '@growthtech/token-mfes-shared/src/atoms/DevelopedByGT'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './resetPassword.schema'
import { resetPassword } from '@growthtech/token-mfes-auth/src/services/auth'
import { useToasts } from 'react-toast-notifications'
import * as S from '../SignUp/styles'
import { IResetPasswordForm } from './resetPassword-form.interface'
import { PasswordField } from '@growthtech/token-mfes-shared/src/atoms/PasswordField'
import { useState } from 'react'
import { LoadingIndicator } from '@growthtech/token-mfes-shared/src/atoms/LoadingIndicator'
import { RouteComponentProps } from 'react-router'
import { IHistoryPush } from '@growthtech/token-mfes-shared/src/interfaces/router'
import { useTranslation } from 'react-i18next';

interface IMatchParams extends RouteComponentProps {
  params: {
    hash: string;
  };
}

const ResetPassword = ({
  history,
  match,
}: {
  history: IHistoryPush;
  match: IMatchParams;
}) => {
  document.title = "Registra Fácil";

  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema),
  });

  const hash = match.params.hash;

  const onSend: SubmitHandler<IResetPasswordForm> = async (data) => {
    try {
      setLoading(true);
      await resetPassword({
        ...data,
        hash,
      });
      history.push("/login");
      setLoading(false);
      addToast(
        t('redefinir_senha.mensagem_sucesso'),
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (e) {
      setLoading(false);
      if (e.response.status === 400) {
        addToast(t('redefinir_senha.erro_expiracao'), {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(t('geral.erro_desconhecido'), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <form onSubmit={handleSubmit(onSend)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <img src={logo} alt="" className="logo" />
                  <h5 className="f-w-700">
                    {t('redefinir_senha.titulo')}
                  </h5>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PasswordField
                        disabled={loading}
                        onChange={onChange}
                        label={t('criar_conta.campo_senha')}
                        placeholder={t('criar_conta.campo_senha_placeholder')}
                        value={value}
                        errorMessage={errors.password?.message}
                        i18nErrorMessage={true}
                      />
                    )}
                  />

                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <PasswordField
                        disabled={loading}
                        onChange={onChange}
                        value={value}
                        label={t('criar_conta.campo_confirmacao_senha')}
                        errorMessage={errors.password_confirmation?.message}
                        i18nErrorMessage={true}
                        placeholder={t('criar_conta.campo_confirmacao_senha_placeholder')}
                      />
                    )}
                    name="password_confirmation"
                    control={control}
                  />

                  <S.ButtonsWrapper>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? t('redefinir_senha.botao_continuar') : <LoadingIndicator />}
                      </button>
                    </div>
                  </S.ButtonsWrapper>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  );
};
export default ResetPassword;
