import { Badge, Card, Col, Row } from "react-bootstrap";
import * as React from "react";
import { useContext } from "react";
import { useIsLogged } from "@growthtech/token-mfes-auth/src/hooks/useIsLogged";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { HeaderCard } from "../HeaderCard";
import { GuestFactoryProps } from "@growthtech/token-mfes-shared/src/interfaces/guest-factory-props";
import { ContractsList } from "../../components/organisms/ContractsList";
import { useRemoteContracts } from "../../hooks/useRemoteContracts";
import { HistoryFilter } from "../../components/organisms/HistoryFilter";
import { CustomPagination } from "@growthtech/token-mfes-shared/src/molecules/CustomPagination";
import { useTranslation } from "react-i18next";

export interface SimplifiedContract {
  id: string;
  object: string;
  value: string;
  url: string;
  documentHash: string;
  transaction_hash: string;
  name: string;
  document_type: string;
  document: string;
  created_at: Date;
  status: string;
  paid_at: Date;
  scope?: string;
  additional_info?: string;
  additional_info_2?: string;
  additional_info_3?: string;
}

export const getStatusBadge = (status: string, t: any) => {
  return (
    <>
      {status === "" && (
        <Badge variant="success">{t("filtros.gratuito")}</Badge>
      )}
      {status === "paid" && (
        <Badge variant="success">{t("filtros.pago")}</Badge>
      )}
      {status === "waiting_payment" && (
        <Badge variant="warning">{t("filtros.aguardando_pagamento")}</Badge>
      )}
    </>
  );
};

interface HistorySimplifiedSmartContractProps {
  history: IHistoryPush;
}

type Props = HistorySimplifiedSmartContractProps & GuestFactoryProps;

export const HistorySimplifiedSmartContract = ({ history, context }: Props) => {
  const { t } = useTranslation();

  // document.title = "PropToken";

  const { user } = useContext(context);

  const { maxPage, currentPage, setFilterData, contracts, reloadData } =
    useRemoteContracts({ my: true, token: user!.token });

  useIsLogged(history, context);

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col md={12}>
            <>
              <HeaderCard context={context} />
              <h4 className="text-white f-w-700 mb-4">{t("history.titulo")}</h4>
              <Card>
                <Card.Body className="card-small-padding">
                  <HistoryFilter onContinue={(data) => setFilterData(data)} />
                  <ContractsList
                    showStatus={true}
                    contracts={contracts}
                    onGoToDetails={(id) =>
                      history.push(`/smart-contract/${id}`)
                    }
                  />
                  <div className="d-flex justify-content-center">
                    <CustomPagination
                      currentPage={currentPage}
                      maxPage={maxPage}
                      onChangePage={(page) => reloadData(page)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </>
          </Col>
        </Row>
      </div>
    </div>
  );
};
