import moment from "moment";
import * as React from "react";
import {
  getStatusBadge,
  SimplifiedContract,
} from "../../../pages/HistorySimplifiedSmartContract";
import Information from "@growthtech/token-mfes-shared/src/atoms/Information";
import { useTranslation } from "react-i18next";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

interface ContractsListProps {
  contracts: SimplifiedContract[];
  onGoToDetails: (id: string) => void;
  showStatus?: boolean;
}

export const ContractsList = ({
  contracts,
  onGoToDetails,
  showStatus,
}: ContractsListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!contracts.length && (
        <Information>{t("registros.mensagem_sem_registros")}</Information>
      )}
      <Table className="table-history">
        <Thead>
          <Tr>
            {/* <Th>Id</Th> */}
            <Th>{t("registros.tabela_coluna_data")}</Th>
            <Th>{t("registros.tabela_coluna_tipo")}</Th>
            <Th>{t("registros.tabela_coluna_valor")}</Th>
            {showStatus && <Th>Status</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {contracts.map((contract) => (
            <Tr key={contract.id} onClick={() => onGoToDetails(contract.id)}>
              {/* <Td>
                        {contract.id}
                    </Td> */}
              <Td>{moment(contract.created_at).format("DD/MM/YYYY HH:mm")}</Td>
              <Td>{t("document_types." + contract.object)}</Td>
              <Td>{contract.value}</Td>
              {showStatus && <Td>{getStatusBadge(contract.status, t)}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};
