import * as S from './style'

interface ItemValidStatusProps {
  text: string
  isValid: boolean
}

export const ItemValidStatus = ({ text, isValid }: ItemValidStatusProps) => {
  return (
    <S.Wrapper>
      {isValid ? (
        <i className="feather icon-check" style={{ color: '#95C567' }} />
      ) : (
        <i className="feather icon-x" style={{ color: '#CC0000' }} />
      )}
      <span style={{ color: isValid ? '#95C567' : '#CC0000' }}>{text}</span>
    </S.Wrapper>
  )
}
