import * as yup from 'yup'
// @ts-ignore
import isValidCep from '@brazilian-utils/is-valid-cep'

export default yup.object().shape({
  name: yup
      .string()
      .required('validacao.credit_card.name_required'),
  document: yup
      .string()
      .required('validacao.credit_card.document_required'),
  card_number: yup
    .string()
    .max(16, 'validacao.credit_card.card_number_max')
    .required('validacao.credit_card.card_number_required'),
  cvv: yup
    .string()
    .min(3, 'validacao.credit_card.cvv_min')
    .max(4, 'validacao.credit_card.cvv_max')
    .required('validacao.credit_card.cvv_required'),
  expiration_month: yup
    .string()
    .required('validacao.credit_card.expiration_month_required'),
  expiration_year: yup
    .string()
    .required('validacao.credit_card.expiration_year_required'),
  zipcode: yup
    .string()
    .test('is-cep', 'validacao.credit_card.zip_code_valid', (value) => isValidCep(value)),
  street: yup.string().required('validacao.credit_card.street_required'),
  number: yup.string().required('validacao.credit_card.number_required'),
  neighborhood: yup.string().required('validacao.credit_card.neighborhood_required'),
  city: yup.string().required('validacao.credit_card.city_required'),
  state: yup.string().required('validacao.credit_card.state_required'),
  birth: yup.string().required('validacao.credit_card.birth_required')
})
