import SignIn from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/SignIn/signIn'
import SignUp from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/SignUp'
import { VerifySimplifiedSmartContract } from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/VerifySimplifiedSmartContract'
import { HistorySimplifiedSmartContract } from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/HistorySimplifiedSmartContract'
import { DetailSimplifiedSmartContract } from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/DetailSimplifiedSmartContract'
import { CreateSimplifiedSmartContract } from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/CreateSimplifiedSmartContract'
// import { Ledger } from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/Ledger'
import ForgotPassword from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/ForgotPassword'
import ResetPassword from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/ResetPassword'
import ConfirmEmail from '@growthtech/token-mfes-simplified-smart-contracts/src/pages/ConfirmEmail'

export interface RouteObject {
  path: string
  exact?: boolean
  name?: string
  component: any
}

const guestRoutes: RouteObject[] = [
  { path: '/', exact: true, name: 'landing', component: SignIn },
  {
    path: '/verify-smart-contract',
    exact: true,
    name: 'VerifySimplifiedSmartContract',
    component: VerifySimplifiedSmartContract
  },

  // {
  //   path: '/ledger',
  //   exact: true,
  //   name: 'Ledger',
  //   component: Ledger
  // },

  {
    path: '/history-smart-contract',
    exact: true,
    name: 'HistorySimplifiedSmartContract',
    component: HistorySimplifiedSmartContract
  },

  {
    path: '/smart-contract/:id',
    exact: false,
    name: 'DetailSimplifiedSmartContract',
    component: DetailSimplifiedSmartContract
  },

  {
    path: '/smart-contract',
    exact: true,
    name: 'CreateSimplifiedSmartContract',
    component: CreateSimplifiedSmartContract
  },

  { path: '/login', exact: true, name: 'login', component: SignIn },

  { path: '/abra-sua-conta', exact: true, name: 'register', component: SignUp },

  {
    path: '/esqueci-minha-senha',
    exact: true,
    name: 'recover-password',
    component: ForgotPassword
  },

  {
    path: '/redefinir-senha/:hash',
    exact: true,
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/confirmar-email/:token',
    exact: true,
    name: 'confirm-email',
    component: ConfirmEmail
  }
]
export default guestRoutes
