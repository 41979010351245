import * as yup from 'yup'
import { isValid as validateCPF } from 'cpf'
import { validate as validateCNPJ } from 'cnpj'

export default yup.object().shape({

  object: yup.string().required('validacao.criar.object_required'),

  email: yup.string()
    .when("object", {
      is: "Óbito",
      then: yup.string().email('validacao.criar.email_invalido'),
      otherwise: yup.string().nullable()
    }),

  nome: yup.string()
    .when("object", {
      is: "Óbito",
      then: yup.string().required('validacao.criar.nome_required'),
      otherwise: yup.string().nullable()
    }),

  file_size: yup.number()
    .when("object", {
      is: "Óbito",
      then: yup.number()
        .nullable(true),
      otherwise: yup.number()
        .required('validacao.criar.file_size_required') // Then require 'file_size'
        .max(10000000, 'validacao.criar.file_size_max')
    }),

  file_type: yup.string()
    .when("object", {
      is: "Óbito",
      then: yup.string().nullable(true),
      otherwise: yup.string()
        .required('validacao.criar.file_type_required')
        .test(
          'fileType',
          'validacao.criar.fileType',
          (value) => value === 'application/pdf'
        )
    }),

  value: yup.string()
    .when("object", {
      is: "Óbito",
      then: yup.string().nullable(),
      otherwise: yup.string().required('validacao.criar.value_required'),
    }),


  parties: yup.array().of(
    yup.object().shape({
      document: yup
        .string()
        .required('validacao.criar.parties_document_required')
        .test('is-cpf', 'validacao.criar.parties_document_valid', (value, context) => {
          const document_type = context.parent.document_type
          return document_type === '1'
            ? validateCPF(value as string)
            : validateCNPJ(value as string)
        }),
      name: yup.string().required('validacao.criar.parties_name_required')
    })
  ).when("object", (object, schema) => object === "Óbito" ? schema.test({
    name: 'isEmpty',
    exclusive: true,
    message: 'validacao.criar.parties_should_be_empty_or_null_if_obito',
    test: (value: any) => value == null || value.length === 0, // Check if the array is empty or null
    }) : schema),

  payment_type: yup.string()
    .when("object", {
      is: "Óbito",
      then: yup.string().nullable(true),
      otherwise: yup.string().required('validacao.criar.payment_type_required'),
    }),

  terms_accepted: yup
    .boolean()
    .test(
      'is-accepted',
        'validacao.criar.terms_accepted',
      (value) => {
        return value === true
      }
    ),
})
