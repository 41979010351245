import { Form } from 'react-bootstrap'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next';

import ErrorText from '../ErrorText'

type TextFieldTypes = InputHTMLAttributes<HTMLInputElement>

export type TextInputProps = {
  label?: string
  type?: 'email' | 'text' | 'password' | 'number' | 'datetime-local' | 'date'
  value?: string | number
  rows?: number
  as?: React.ElementType
  errorMessage?: string
  i18nErrorMessage?: boolean
} & Omit<TextFieldTypes, 'size' | 'value'>

const TextInputComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextInputProps
> = ({ label, type = 'text', placeholder, errorMessage, i18nErrorMessage=false, ...other }, ref) => {
  const { t } = useTranslation();

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          <strong>{label}</strong>
        </Form.Label>
      )}
      <Form.Control
        isInvalid={!!errorMessage}
        autoComplete={label}
        type={type}
        placeholder={placeholder}
        {...other}
        ref={ref}
      />
      {errorMessage && !i18nErrorMessage && <ErrorText text={errorMessage} />}
      {errorMessage && i18nErrorMessage && <ErrorText text={t(errorMessage)} />}
    </Form.Group>
  )
}

export const TextInput = React.forwardRef(TextInputComponent)
