import { Button, Card, Col, Row } from "react-bootstrap";
import { InputFile } from "@growthtech/token-mfes-shared/src/molecules/InputFile";
import { Controller, useForm } from "react-hook-form";
import { SelectField } from "@growthtech/token-mfes-shared/src/atoms/SelectField";
import { TextInputWithMask } from "@growthtech/token-mfes-shared/src/atoms/TextInputWithMask";
import { TextInput } from "@growthtech/token-mfes-shared/src/atoms/TextInput";
import { Checkbox } from "@growthtech/token-mfes-shared/src/atoms/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../create-simplified-smart-contract.schema";
import { CreateSimplifiedSmartContract } from "../index";
import getObjectTypes from "../../../services/getObjectTypes";
import { useTranslation } from "react-i18next";
import { useGetCpf } from "@growthtech/token-mfes-persons/src/hooks/useGetCpf";
import { useGetCnpj } from "@growthtech/token-mfes-persons/src/hooks/useGetCnpj";

interface FormProps {
  onContinue: (data: CreateSimplifiedSmartContract) => Promise<void>;
  file: any;
  setFile: any;
}

export const Form = ({ onContinue, file, setFile }: FormProps) => {
  const { t } = useTranslation();
  const { getCpf } = useGetCpf();
  const { getCnpj } = useGetCnpj();

  const booleanOptions = [
    {
      label: t("geral.nao"),
      value: "",
    },
    {
      label: t("geral.sim"),
      value: "1",
    },
  ]

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateSimplifiedSmartContract>({
    resolver: yupResolver(schema),
    defaultValues: {
      object: "",
      value: "",
      document_type: "",
      document: "",
      name: "",
      payment_type: "",
      scope: "",
    },
  });

  const document_type = watch("document_type");
  const object = watch("object");

  const checkSerpro = async (document: string) => {
    if (document_type === "1") {
      const cpfDto = await getCpf(document);
      if (!cpfDto) return;
      setValue("name", cpfDto.nome);
    } else {
      const cnpjDto = await getCnpj(document);
      if (!cnpjDto) return;
      setValue("name", cnpjDto.nomeEmpresarial);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onContinue)}>
        <Card>
          <Card.Body className="card-small-padding">
            <Row>
              <Col md={12}>
                <h5 className="mb-4">{t("registrar.documento.titulo")}</h5>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  control={control}
                  name="object"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      emptyOptionText={t("geral.select_placeholder")}
                      options={getObjectTypes(t)}
                      onChange={onChange}
                      value={value}
                      label={t("registrar.documento.campo_tipo")}
                      errorMessage={errors.object?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              { object == 'Óbito' && <Col md={6}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      label={t("registrar.documento.email")}
                      placeholder={t("registrar.documento.email_placeholder")}
                      value={value}
                      onChange={onChange}
                      errorMessage={errors.email?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col> }
              { object != 'Óbito' && <>
              <Col md={4}>
                <InputFile
                  label={t("registrar.documento.campo_pdf")}
                  btnText={file?.name || t("registrar.documento.campo_pdf")}
                  onChange={(file) => {
                    setValue("file_type", file.type, {
                      shouldValidate: true,
                    });
                    setValue("file_size", file.size, {
                      shouldValidate: true,
                    });
                    setFile(file);
                  }}
                  errorMessage={
                    errors.file_type?.message || errors.file_size?.message
                  }
                  i18nErrorMessage={true}
                />
              </Col>

              <Col md={4}>
                <Controller
                  control={control}
                  name="value"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("valores.ate_500"),
                          value: "Até R$ 500,00",
                        },
                        {
                          label: t("valores.de_500_a_mil"),
                          value: "De R$ 501,00 até R$ 1.000,00",
                        },
                        {
                          label: t("valores.de_mil_a_dez_mil"),
                          value: "De R$ 1.000,01 até R$ 10.000,00",
                        },
                        {
                          label: t("valores.de_dez_mil_a_cinquenta_mil"),
                          value: "De R$ 10.000,01 até R$ 50.000,00",
                        },
                        {
                          label: t("valores.de_cinquenta_mil_a_cem_mil"),
                          value: "De R$ 50.000,01 até R$ 100.000,00",
                        },
                        {
                          label: t(
                            "valores.de_cem_mil_a_duzentos_e_cinquenta_mil"
                          ),
                          value: "De R$ 100.000,01 até R$ 250.000,00",
                        },
                        {
                          label: t(
                            "valores.de_duzentos_e_cinquenta_mil_a_quinhentos_mil"
                          ),
                          value: "De R$ 250.000,01 até R$ 500.000,00",
                        },
                        {
                          label: t("valores.de_quinhentos_mil_a_um_milhao"),
                          value: "De R$ 500.000,01 até R$ 1.000.000,00",
                        },
                        {
                          label: t(
                            "valores.de_um_milhao_a_dois_milhoes_e_meio"
                          ),
                          value: "De R$ 1.000.000,01 até R$ 2.500.000,00",
                        },
                        {
                          label: t(
                            "valores.de_dois_milhoes_e_meio_a_cinco_milhoes"
                          ),
                          value: "De R$ 2.500.000,01 até R$ 5.000.000,00",
                        },
                        {
                          label: t("valores.de_cinco_milhoes_a_dez_milhoes"),
                          value: "De R$ 5.000.000,01 até R$ 10.000.000,00",
                        },
                        {
                          label: t("valores.acima_de_10_milhoes"),
                          value: "Acima de R$ 10.000.000,00",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={t("registrar.documento.campo_valor")}
                      errorMessage={errors.value?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              </> }
            </Row>
            {/* <Row> */}
            {/* <Col md={4}>
                <Controller
                  control={control}
                  name="scope"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("registrar.documento.publico"),
                          value: "Público",
                        },
                        {
                          label: t("registrar.documento.privado"),
                          value: "Privado",
                        },
                        {
                          label: t("registrar.documento.hibrido"),
                          value: "Híbrido",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={t("registrar.documento.campo_publico_privado")}
                      errorMessage={errors.scope?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col> */}
            {/* </Row> */}
            { object != 'Óbito' &&
            <Row>
              <Col md={4}>
                <Controller
                  control={control}
                  name="additional_info"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      maxLength={20}
                      label={t("registrar.documento.campo_livre_1")}
                      value={value}
                      onChange={onChange}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="additional_info_2"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      maxLength={20}
                      label={t("registrar.documento.campo_livre_2")}
                      value={value}
                      onChange={onChange}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="additional_info_3"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      maxLength={20}
                      label={t("registrar.documento.campo_livre_3")}
                      value={value}
                      onChange={onChange}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row> }
          </Card.Body>
        </Card>

        {/*<Card className="mt-3">*/}
        {/*  <Card.Body className="card-small-padding">*/}
        {/*    <Row>*/}
        {/*      <Col md={12}>*/}
        {/*        <h5 className="mb-4">{t("registrar.responsavel.titulo")}</h5>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row>*/}
        {/*      <Col md={4}>*/}
        {/*        <Controller*/}
        {/*          name="document_type"*/}
        {/*          control={control}*/}
        {/*          render={({ field: { onChange, value } }) => (*/}
        {/*            <SelectField*/}
        {/*              label={t("registrar.responsavel.campo_tipo_documento")}*/}
        {/*              emptyOptionText={t("geral.select_placeholder")}*/}
        {/*              onChange={(event) => {*/}
        {/*                onChange(event);*/}
        {/*                setValue("document", "");*/}
        {/*                setValue("name", "");*/}
        {/*              }}*/}
        {/*              value={value}*/}
        {/*              options={[*/}
        {/*                { label: "CPF", value: "1" },*/}
        {/*                { label: "CNPJ", value: "0" },*/}
        {/*              ]}*/}
        {/*              errorMessage={errors.document_type?.message}*/}
        {/*              i18nErrorMessage={true}*/}
        {/*            />*/}
        {/*          )}*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*      <Col md={4}>*/}
        {/*        <Controller*/}
        {/*          name="document"*/}
        {/*          control={control}*/}
        {/*          render={({ field: { onChange, value } }) => (*/}
        {/*            <TextInputWithMask*/}
        {/*              label={t("registrar.responsavel.campo_documento")}*/}
        {/*              errorMessage={errors?.document?.message}*/}
        {/*              mask={*/}
        {/*                document_type === "1"*/}
        {/*                  ? "999.999.999-99"*/}
        {/*                  : "99.999.999/9999-99"*/}
        {/*              }*/}
        {/*              placeholder={*/}
        {/*                document_type === "1"*/}
        {/*                  ? "ex: 999.999.999-99"*/}
        {/*                  : "ex: 99.999.999/9999-99"*/}
        {/*              }*/}
        {/*              value={value}*/}
        {/*              onChange={({ target: { value } }) => {*/}
        {/*                onChange(value);*/}
        {/*                checkSerpro(value);*/}
        {/*              }}*/}
        {/*              i18nErrorMessage={true}*/}
        {/*            />*/}
        {/*          )}*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*      <Col md={4}>*/}
        {/*        <Controller*/}
        {/*          name="name"*/}
        {/*          control={control}*/}
        {/*          render={({ field: { onChange, value } }) => (*/}
        {/*            <TextInput*/}
        {/*              label={t("registrar.responsavel.campo_nome")}*/}
        {/*              errorMessage={errors?.document?.message}*/}
        {/*              value={value}*/}
        {/*              onChange={onChange}*/}
        {/*              i18nErrorMessage={true}*/}
        {/*              placeholder="Campo preenchido automaticamente"*/}
        {/*              disabled={true}*/}
        {/*            />*/}
        {/*          )}*/}
        {/*        />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Card.Body>*/}
        {/*</Card>*/}

        { object == 'Óbito' && <Card className="mt-3">
          <Card.Body className="card-small-padding">
            <h5 className="mb-4">{t("Dados do Registro de Óbito")}</h5>
            <h4></h4>
            <Row>
              <Col md={4}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome"}
                      {...field}
                      errorMessage={errors.nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="data_registro"
                  control={control}
                  render={({field}) => (
                    <TextInput
                      type={'date'}
                      label={"Data do Registro"}
                      {...field}
                      errorMessage={errors.data_registro?.message}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="nro_registro"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Número de Registro"}
                      {...field}
                      errorMessage={errors.nro_registro?.message}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="tipo_obito"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Associado"),
                          value: "Associado",
                        },
                        {
                          label: t("Particular"),
                          value: "Particular",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Tipo de Óbito"}
                      errorMessage={errors.tipo_obito?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="inscricao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Inscrição"}
                      {...field}
                      errorMessage={errors.inscricao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="plano"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Plano"}
                      {...field}
                      errorMessage={errors.plano?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="sexo"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Masculino"),
                          value: "Masculino",
                        },
                        {
                          label: t("Feminino"),
                          value: "Feminino",
                        },
                        {
                          label: t("Outro"),
                          value: "Outro",
                        },
                        {
                          label: t("Não informado"),
                          value: "Não informado",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Sexo"}
                      errorMessage={errors.sexo?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="cor"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cor"}
                      {...field}
                      errorMessage={errors.cor?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="profissao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Profissão"}
                      {...field}
                      errorMessage={errors.profissao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="natural"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Naturalidade"}
                      {...field}
                      errorMessage={errors.natural?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="cep"
                  control={control}
                  render={({ field }) => (
                    <TextInputWithMask
                      mask="99999-999"
                      label={"CEP"}
                      placeholder="99999-999"
                      errorMessage={errors?.cep?.message}
                      i18nErrorMessage={true}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="residencia"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Endereço"}
                      {...field}
                      errorMessage={errors.residencia?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="bairro"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Bairro"}
                      {...field}
                      errorMessage={errors.bairro?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="cidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade"}
                      {...field}
                      errorMessage={errors.cidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="uf"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"UF"}
                      {...field}
                      errorMessage={errors.uf?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="inss"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Sim"),
                          value: "Sim",
                        },
                        {
                          label: t("Não"),
                          value: "Não",
                        },
                        {
                          label: t("Ignorado"),
                          value: "Ignorado",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"INSS"}
                      errorMessage={errors.inss?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="id_beneficio"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"ID do Benefício"}
                      {...field}
                      errorMessage={errors.id_beneficio?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="est_civil"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Solteiro(a)"),
                          value: "Solteiro(a)",
                        },
                        {
                          label: t("Casado(a)"),
                          value: "Casado(a)",
                        },
                        {
                          label: t("União Estável"),
                          value: "União Estável",
                        },
                        {
                          label: t("Divorciado(a)"),
                          value: "Divorciado(a)",
                        },
                        {
                          label: t("Víuvo(a)"),
                          value: "Víuvo(a)",
                        },
                        {
                          label: t("Separado(a)"),
                          value: "Separado(a)",
                        },
                        {
                          label: t("Outro"),
                          value: "Outro",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Estado Civil"}
                      errorMessage={errors.est_civil?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="rg"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"RG"}
                      {...field}
                      errorMessage={errors.rg?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="dt_expedicao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Data de Expedição"}
                      type={'date'}
                      {...field}
                      errorMessage={errors.dt_expedicao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="orgao_emissor"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Órgão Emissor"}
                      {...field}
                      errorMessage={errors.orgao_emissor?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field }) => (
                    <TextInputWithMask
                      mask="999.999.999-99"
                      label={"CPF"}
                      placeholder="999.999.999-99"
                      errorMessage={errors?.cpf?.message}
                      i18nErrorMessage={true}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="bens"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Sim"),
                          value: "Sim",
                        },
                        {
                          label: t("Não"),
                          value: "Não",
                        },
                        {
                          label: t("Ignorado"),
                          value: "Ignorado",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Bens"}
                      errorMessage={errors.bens?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="testamento"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Sim"),
                          value: "Sim",
                        },
                        {
                          label: t("Não"),
                          value: "Não",
                        },
                        {
                          label: t("Ignorado"),
                          value: "Ignorado",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Testamento"}
                      errorMessage={errors.testamento?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="eleitor"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Sim"),
                          value: "Sim",
                        },
                        {
                          label: t("Não"),
                          value: "Não",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Eleitor"}
                      errorMessage={errors.eleitor?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="numero"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Número"}
                      {...field}
                      errorMessage={errors.numero?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="zona"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Zona"}
                      {...field}
                      errorMessage={errors.zona?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="secao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Seção"}
                      {...field}
                      errorMessage={errors.secao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="cidade_eleitor"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade do Eleitor"}
                      {...field}
                      errorMessage={errors.cidade_eleitor?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="reserv"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Sim"),
                          value: "Sim",
                        },
                        {
                          label: t("Não"),
                          value: "Não",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Reservista"}
                      errorMessage={errors.reserv?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="ident"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Ident. Reservista"}
                      {...field}
                      errorMessage={errors.ident?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="nascimento"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nascimento"}
                      type={'date'}
                      {...field}
                      errorMessage={errors.nascimento?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="idade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Idade"}
                      {...field}
                      errorMessage={errors.idade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="religiao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Religião"}
                      {...field}
                      errorMessage={errors.religiao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="nacionalidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nacionalidade"}
                      {...field}
                      errorMessage={errors.nacionalidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="carteira_trabalho"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Carteira de Trabalho"}
                      {...field}
                      errorMessage={errors.carteira_trabalho?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <h4>Cônjuge</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="conjuge_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Cônjuge"}
                      {...field}
                      errorMessage={errors.conjuge_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="data_casamento"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Data Casamento"}
                      type={'date'}
                      {...field}
                      errorMessage={errors.data_casamento?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="cartorio_casamento"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cartório de Casamento"}
                      {...field}
                      errorMessage={errors.cartorio_casamento?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="casamento_cartorio_livro"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Livro"}
                      {...field}
                      errorMessage={errors.casamento_cartorio_livro?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="casamento_cartorio_folha"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Folha"}
                      {...field}
                      errorMessage={errors.casamento_cartorio_folha?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="casamento_cartorio_numero"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Número"}
                      {...field}
                      errorMessage={errors.casamento_cartorio_numero?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <h4>Filiação</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="pai_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Pai"}
                      {...field}
                      errorMessage={errors.pai_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="pai_est_civil"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Solteiro(a)"),
                          value: "Solteiro(a)",
                        },
                        {
                          label: t("Casado(a)"),
                          value: "Casado(a)",
                        },
                        {
                          label: t("União Estável"),
                          value: "União Estável",
                        },
                        {
                          label: t("Divorciado(a)"),
                          value: "Divorciado(a)",
                        },
                        {
                          label: t("Víuvo(a)"),
                          value: "Víuvo(a)",
                        },
                        {
                          label: t("Separado(a)"),
                          value: "Separado(a)",
                        },
                        {
                          label: t("Outro"),
                          value: "Outro",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Estado Civil do Pai"}
                      errorMessage={errors.pai_est_civil?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="pai_natural"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Naturalidade do Pai"}
                      {...field}
                      errorMessage={errors.pai_natural?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="pai_profissao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Profissão do Pai"}
                      {...field}
                      errorMessage={errors.pai_profissao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="pai_endereco"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Endereço do Pai"}
                      {...field}
                      errorMessage={errors.pai_endereco?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="pai_bairro"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Bairro do Pai"}
                      {...field}
                      errorMessage={errors.pai_bairro?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="pai_cidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade do Pai"}
                      {...field}
                      errorMessage={errors.pai_cidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="pai_uf"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"UF do Pai"}
                      {...field}
                      errorMessage={errors.pai_uf?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome da Mãe"}
                      {...field}
                      errorMessage={errors.mae_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="mae_est_civil"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Solteiro(a)"),
                          value: "Solteiro(a)",
                        },
                        {
                          label: t("Casado(a)"),
                          value: "Casado(a)",
                        },
                        {
                          label: t("União Estável"),
                          value: "União Estável",
                        },
                        {
                          label: t("Divorciado(a)"),
                          value: "Divorciado(a)",
                        },
                        {
                          label: t("Víuvo(a)"),
                          value: "Víuvo(a)",
                        },
                        {
                          label: t("Separado(a)"),
                          value: "Separado(a)",
                        },
                        {
                          label: t("Outro"),
                          value: "Outro",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Estado Civil da Mãe"}
                      errorMessage={errors.mae_est_civil?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_natural"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Naturalidade da Mãe"}
                      {...field}
                      errorMessage={errors.mae_natural?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_profissao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Profissão da Mãe"}
                      {...field}
                      errorMessage={errors.mae_profissao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_endereco"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Endereço da Mãe"}
                      {...field}
                      errorMessage={errors.mae_endereco?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_bairro"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Bairro da Mãe"}
                      {...field}
                      errorMessage={errors.mae_bairro?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_cidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade da Mãe"}
                      {...field}
                      errorMessage={errors.mae_cidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="mae_uf"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"UF da Mãe"}
                      {...field}
                      errorMessage={errors.mae_uf?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4>Dados do Óbito</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="data_falecimento"
                  control={control}
                  render={({field}) => (
                    <TextInput
                      type={'datetime-local'}
                      label={"Data do Falecimento"}
                      {...field}
                      errorMessage={errors.data_falecimento?.message}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="falecimento_local"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Local do Falecimento"}
                      {...field}
                      errorMessage={errors.falecimento_local?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="falecimento_cidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade do Falecimento"}
                      {...field}
                      errorMessage={errors.falecimento_cidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="falecimento_uf"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"UF do Falecimento"}
                      {...field}
                      errorMessage={errors.falecimento_uf?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="sepultamento"
                  control={control}
                  render={({field}) => (
                    <TextInput
                      type={'datetime-local'}
                      label={"Data do Sepultamento"}
                      {...field}
                      errorMessage={errors.sepultamento?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="sepultamento_local"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Local do Sepultamento"}
                      {...field}
                      errorMessage={errors.sepultamento_local?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="sepultamento_cidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade do Sepultamento"}
                      {...field}
                      errorMessage={errors.sepultamento_cidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="sepultamento_uf"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"UF do Sepultamento"}
                      {...field}
                      errorMessage={errors.sepultamento_uf?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="medico_1_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Médico 1"}
                      {...field}
                      errorMessage={errors.medico_1_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="medico_1_crm"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"CRM do Médico 1"}
                      {...field}
                      errorMessage={errors.medico_1_crm?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="medico_1_decl"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Declaração do Médico 1"}
                      {...field}
                      errorMessage={errors.medico_1_decl?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="medico_2_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Médico 2"}
                      {...field}
                      errorMessage={errors.medico_2_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="medico_2_crm"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"CRM do Médico 2"}
                      {...field}
                      errorMessage={errors.medico_2_crm?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="causa_1"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Causa 1"}
                      {...field}
                      errorMessage={errors.causa_1?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="causa_2"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Causa 2"}
                      {...field}
                      errorMessage={errors.causa_2?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="causa_3"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Causa 3"}
                      {...field}
                      errorMessage={errors.causa_3?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="causa_4"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Causa 4"}
                      {...field}
                      errorMessage={errors.causa_4?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <h4>Filhos</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="filho_1_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Filho 1"}
                      {...field}
                      errorMessage={errors.filho_1_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_1_idade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Idade do Filho 1"}
                      {...field}
                      type="number"
                      errorMessage={errors.filho_1_idade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_2_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Filho 2"}
                      {...field}
                      errorMessage={errors.filho_2_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_2_idade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Idade do Filho 2"}
                      {...field}
                      type="number"
                      errorMessage={errors.filho_2_idade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_3_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Filho 3"}
                      {...field}
                      errorMessage={errors.filho_3_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_3_idade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Idade do Filho 3"}
                      {...field}
                      type="number"
                      errorMessage={errors.filho_3_idade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_4_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome do Filho 4"}
                      {...field}
                      errorMessage={errors.filho_4_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="filho_4_idade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Idade do Filho 4"}
                      {...field}
                      type="number"
                      errorMessage={errors.filho_2_idade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <h4>Observação</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="observacao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Observação"}
                      {...field}
                      errorMessage={errors.observacao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <h4>Declarante</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="declarante_cartorio"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cartório"}
                      {...field}
                      errorMessage={errors.declarante_cartorio?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_cartorio_end"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Endereço do Cartório"}
                      {...field}
                      errorMessage={errors.declarante_cartorio_end?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_cartorio_fone"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Telefone do Cartório"}
                      {...field}
                      errorMessage={errors.declarante_cartorio_fone?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome"}
                      {...field}
                      errorMessage={errors.declarante_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_rg"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"RG"}
                      {...field}
                      errorMessage={errors.declarante_rg?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_org_exp"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Órgão Expedidor do RG"}
                      {...field}
                      errorMessage={errors.declarante_org_exp?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_cpf"
                  control={control}
                  render={({ field }) => (
                    <TextInputWithMask
                      mask="999.999.999-99"
                      label={"CPF"}
                      placeholder="999.999.999-99"
                      errorMessage={errors?.declarante_cpf?.message}
                      i18nErrorMessage={true}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_profissao"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Profissão"}
                      {...field}
                      errorMessage={errors.declarante_profissao?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  control={control}
                  name="declarante_est_civil"
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      notDisabledEmpty={true}
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("Solteiro(a)"),
                          value: "Solteiro(a)",
                        },
                        {
                          label: t("Casado(a)"),
                          value: "Casado(a)",
                        },
                        {
                          label: t("União Estável"),
                          value: "União Estável",
                        },
                        {
                          label: t("Divorciado(a)"),
                          value: "Divorciado(a)",
                        },
                        {
                          label: t("Víuvo(a)"),
                          value: "Víuvo(a)",
                        },
                        {
                          label: t("Separado(a)"),
                          value: "Separado(a)",
                        },
                        {
                          label: t("Outro"),
                          value: "Outro",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={"Estado Civil"}
                      errorMessage={errors.declarante_est_civil?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_nacionalidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nacionalidade"}
                      {...field}
                      errorMessage={errors.declarante_nacionalidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_parentesco"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Parentesco"}
                      {...field}
                      errorMessage={errors.declarante_parentesco?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_endereco"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Endereço"}
                      {...field}
                      errorMessage={errors.declarante_endereco?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_bairro"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Bairro"}
                      {...field}
                      errorMessage={errors.declarante_bairro?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_cidade"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Cidade"}
                      {...field}
                      errorMessage={errors.declarante_cidade?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_cep"
                  control={control}
                  render={({ field }) => (
                    <TextInputWithMask
                      mask="99999-999"
                      label={"CEP"}
                      placeholder="99999-999"
                      errorMessage={errors?.declarante_cep?.message}
                      i18nErrorMessage={true}
                      {...field}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_estado"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Estado"}
                      {...field}
                      errorMessage={errors.declarante_estado?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_fone"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Telefone"}
                      {...field}
                      errorMessage={errors.declarante_fone?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_fone2"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Telefone 2"}
                      {...field}
                      errorMessage={errors.declarante_fone2?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="declarante_fone3"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Telefone 3"}
                      {...field}
                      errorMessage={errors.declarante_fone3?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <hr/>
                <h4>Atendente</h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="atendente_nome"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Nome"}
                      {...field}
                      errorMessage={errors.atendente_nome?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="atendente_rg"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"RG"}
                      {...field}
                      errorMessage={errors.atendente_rg?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="atendente_org_exp"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Órgão Expedidor do RG"}
                      {...field}
                      errorMessage={errors.atendente_org_exp?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <Controller
                  name="atendente_lancamento"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={"Lançamento"}
                      {...field}
                      errorMessage={errors.atendente_lancamento?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>

            </Row>

          </Card.Body>
        </Card> }



        <Card className="mt-3">
          <Card.Body className="card-small-padding">
            { object != 'Óbito' && <>
            <Row>
              <Col md={12}>
                <h5 className="mb-4">{t("registrar.pagamento.titulo")}</h5>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Controller
                  name="payment_type"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectField
                      emptyOptionText={t("geral.select_placeholder")}
                      options={[
                        {
                          label: t("registrar.pagamento.boleto"),
                          value: "bank_billet",
                        },
                        {
                          label: t("registrar.pagamento.cartao"),
                          value: "credit_card",
                        },
                      ]}
                      onChange={onChange}
                      value={value}
                      label={t("registrar.pagamento.campo_forma_de_pagto")}
                      errorMessage={errors.payment_type?.message}
                      i18nErrorMessage={true}
                    />
                  )}
                />
              </Col>
            </Row>
            </> }
            <Row className="mt-3" noGutters={true}>
              <Col md={8}>
                <Controller
                  name="terms_accepted"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <div>
                      <Checkbox
                        label={
                          <>
                            <p style={{ fontSize: 12, fontWeight: "500" }}>
                              {t("registrar.aceite_termos")}
                              <a
                                style={{ fontWeight: "500" }}
                                href={process.env.REACT_APP_TOS_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("registrar.termos_de_uso")}
                              </a>
                            </p>
                          </>
                        }
                        defaultChecked={false}
                        errorMessage={errors.terms_accepted?.message}
                        onChange={onChange}
                        i18nErrorMessage={true}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col md={4}>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn btn-block"
                >
                  {t("registrar.botao_continuar")}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </form>
    </>
  );
};
