import * as S from './styles'
import { ItemValidStatus } from '@growthtech/token-mfes-shared/src/atoms/ItemValidStatus'

interface PasswordValidatorProps {
  password: string;
  min_chars_text?: string
  uppercase_text?: string
  lowercase_text?: string,
  number_text?: string
  special_chars_text?: string
}

export const PasswordValidator = ({ password,
                                    min_chars_text = "8 Caracteres",
                                    uppercase_text = "1 Letra Maiúscula",
                                    lowercase_text = "1 Letra Minúscula",
                                    number_text = "1 Número",
                                    special_chars_text = "1 Caractere Especial"
}: PasswordValidatorProps) => {
  const eightCharsIsValid = password?.length >= 8;
  const uppercaseIsValid = !!password?.match(/[A-Z]/);
  const lowerCaseIsValid = !!password?.match(/[a-z]/);
  const numberIsValid = !!password?.match(/\d/);
  const specialCharIsValid = !!password?.match(/[!@#$&*]/);

  return (
    <S.Wrapper>
      <div className="row">
        <div className="col-md-4">
          <ItemValidStatus text={min_chars_text} isValid={eightCharsIsValid} />
        </div>
        <div className="col-md-4">
          <ItemValidStatus
            text={uppercase_text}
            isValid={uppercaseIsValid}
          />
        </div>
        <div className="col-md-4">
          <ItemValidStatus
            text={lowercase_text}
            isValid={lowerCaseIsValid}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <ItemValidStatus text={number_text} isValid={numberIsValid} />
        </div>
        <div className="col-md-4">
          <ItemValidStatus
            text={special_chars_text}
            isValid={specialCharIsValid}
          />
        </div>
      </div>
    </S.Wrapper>
  );
};
