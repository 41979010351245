import * as React from "react";
import { NavLink } from "react-router-dom";
import { Card, Form, FormCheck } from "react-bootstrap";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { logo } from "@growthtech/token-mfes-shared/src/contants/logo";

import { DevelopedByGT } from "@growthtech/token-mfes-shared/src/atoms/DevelopedByGT";
import { PasswordField } from "@growthtech/token-mfes-shared/src/atoms/PasswordField";
import { PasswordValidator } from "@growthtech/token-mfes-auth/src/components/molecules/PasswordValidator";

import { TextInput } from "@growthtech/token-mfes-shared/src/atoms/TextInput";
import { TextInputWithMask } from "@growthtech/token-mfes-shared/src/atoms/TextInputWithMask";
import { signUp } from "@growthtech/token-mfes-auth/src/services/users";
import { useToasts } from "react-toast-notifications";
import schema from "./signup.schema";
import { ISignUpForm } from "./signup-form.interface";

import * as S from "./styles";
import { useState } from "react";
import { LoadingIndicator } from "@growthtech/token-mfes-shared/src/atoms/LoadingIndicator";
import { IHistoryPush } from "@growthtech/token-mfes-shared/src/interfaces/router";
import { useGetCpf } from "@growthtech/token-mfes-persons/src/hooks/useGetCpf";

import { useTranslation } from "react-i18next";

interface Props {
  history: IHistoryPush;
}

const SignUp = ({ history }: Props) => {
  document.title = "Registra Fácil";
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const { getCpf } = useGetCpf();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ISignUpForm>({
    resolver: yupResolver(schema),
  });

  const password = watch("password");
  const onSubmit: SubmitHandler<ISignUpForm> = async (data) => {
    try {
      setLoading(true);
      await signUp({ ...data, document: data.cpf, type: "common" });
      addToast(t("criar_conta.mensagem_email"), {
        appearance: "success",
        autoDismiss: true,
      });
      setLoading(false);
      history.push("/login");
    } catch (e) {
      setLoading(false);
      if (e?.response.status === 409) {
        addToast(
          <span>
            {t("criar_conta.mensagem_conta_existente")}
            <button
              className="btn btn-sm btn-danger"
              style={{ marginTop: "5px" }}
              onClick={() => history.push("/esqueci-minha-senha")}
            >
              {t("criar_conta.botao_recuperar")}
            </button>
            .
          </span>,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      } else {
        addToast(t("geral.erro_desconhecido"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  const checkCpf = async (cpf: string) => {
    try {
      const cpfDto = await getCpf(cpf);
      if (!cpfDto) return;
      setValue("name", cpfDto.nome);
    } catch {
      reset({ cpf: "", name: "" });
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content" style={{ width: "800px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Card.Body>
                  <div className="d-flex justify-content-around align-items-center row">
                    <img src={logo} alt="" className="logo mb-4" />
                  </div>
                  <h5 className="f-w-700 mb-4">{t("criar_conta.titulo")}</h5>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="cpf"
                            render={({ field: { onChange, value } }) => (
                              <TextInputWithMask
                                disabled={loading}
                                label="CPF"
                                placeholder={t("criar_conta.cpf_placeholder")}
                                mask="999.999.999-99"
                                value={value}
                                autoComplete="off"
                                errorMessage={errors.cpf?.message}
                                i18nErrorMessage={true}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  checkCpf(value);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                disabled={loading}
                                readOnly
                                label={t("criar_conta.campo_nome")}
                                autoComplete="off"
                                placeholder={t(
                                  "criar_conta.campo_nome_placeholder"
                                )}
                                type="text"
                                value={value}
                                onChange={onChange}
                                errorMessage={errors.name?.message}
                                i18nErrorMessage={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, value } }) => (
                              <TextInput
                                disabled={loading}
                                label={t("criar_conta.campo_email")}
                                placeholder={t(
                                  "criar_conta.campo_email_placeholder"
                                )}
                                autoComplete="off"
                                type="email"
                                errorMessage={errors.email?.message}
                                i18nErrorMessage={true}
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="phone"
                            render={({ field: { onChange, value } }) => (
                              <TextInputWithMask
                                disabled={loading}
                                label={t("criar_conta.campo_telefone")}
                                autoComplete="off"
                                placeholder={t(
                                  "criar_conta.campo_telefone_placeholder"
                                )}
                                mask="(99) 9 9999-9999"
                                errorMessage={errors.phone?.message}
                                i18nErrorMessage={true}
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, value } }) => (
                              <PasswordField
                                disabled={loading}
                                label={t("criar_conta.campo_senha")}
                                placeholder={t(
                                  "criar_conta.campo_senha_placeholder"
                                )}
                                onChange={onChange}
                                value={value}
                                autoComplete="off"
                                errorMessage={errors.password?.message}
                                i18nErrorMessage={true}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <Controller
                            control={control}
                            name="password_confirmation"
                            render={({ field: { onChange, value } }) => (
                              <PasswordField
                                disabled={loading}
                                onChange={onChange}
                                value={value}
                                autoComplete="off"
                                label={t("criar_conta.campo_confirmacao_senha")}
                                errorMessage={
                                  errors.password_confirmation?.message
                                }
                                i18nErrorMessage={true}
                                placeholder={t(
                                  "criar_conta.campo_confirmacao_senha_placeholder"
                                )}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <PasswordValidator
                            password={password}
                            min_chars_text={t(
                              "validacao_senha.qtde_caracteres"
                            )}
                            uppercase_text={t("validacao_senha.maiuscula")}
                            lowercase_text={t("validacao_senha.minuscula")}
                            number_text={t("validacao_senha.numero")}
                            special_chars_text={t("validacao_senha.especial")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex m-r-10 align-items-center">
                    <Controller
                      control={control}
                      name="privacy_policy"
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <FormCheck
                            type="checkbox"
                            id="privacyPolicy"
                            onChange={onChange}
                            checked={value}
                            autoComplete="off"
                          />
                          <Form.Label
                            style={{ marginBottom: 0 }}
                            htmlFor="privacyPolicy"
                          >
                            {t("criar_conta.aceite_termos")}{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={process.env.REACT_APP_TOS_URL}
                            >
                              {t("criar_conta.termos_de_uso")}{" "}
                            </a>
                          </Form.Label>
                        </>
                      )}
                    />
                  </div>
                  <S.ErrorMessage>
                    {errors.privacy_policy?.message &&
                      t(errors.privacy_policy?.message)}
                  </S.ErrorMessage>
                  <S.ButtonsWrapper>
                    <div className="">
                      {!loading && (
                        <NavLink to="/login" className="f-w-400">
                          <a className="btn">{t("geral.voltar")}</a>
                        </NavLink>
                      )}
                    </div>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {!loading ? (
                          t("criar_conta.botao")
                        ) : (
                          <LoadingIndicator />
                        )}
                      </button>
                    </div>
                  </S.ButtonsWrapper>
                </Card.Body>
              </div>
            </div>
          </Card>
        </form>
        <DevelopedByGT />
      </div>
    </div>
  );
};
export default SignUp;
